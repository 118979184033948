import React, {useState, useRef, useEffect} from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { HashRouter } from 'react-router-dom'
import './styles/app.scss'
import routes from './RootRoutes'
import { connect } from 'react-redux'
import { logoutUser } from './actions/auth'
import { ToastContainer } from 'react-toastify'
import Login from "./pages/login/Login"
import ErrorPage from './pages/error/ErrorPage'
import Register from './pages/register/Register'
import Encfinalizada from './pages/encrealizada/Encrealizada'

import isAuthenticated from './services/authService'
import LayoutComponent from './components/Layout/Layout'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ConfigProvider } from 'antd';
import Encuesta from './pages/encuesta/Enc';
import DashboardCouch from './pages/dashboard/DashboardCouch'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import esES from "antd/locale/es_ES";
import Reporte from './pages/empadmin/reportes/Reporte';
import ReportePlanAccion from './pages/empadmin/reportes/ReportePlanAccion';
import LandigPage from "./pages/landigpage/index";
import Equipo from "./pages/landigpage/Equipo";
import Funcionalidades from "./pages/landigpage/Funcionalidades";
import Contactenos from "./pages/landigpage/Contactenos";
import RecuperarContraseña from "./pages/login/RecuperarContraseña";
import Encuestaburn from "./pages/encuesta/Encb";



dayjs.locale('es')

const theme = createTheme({
  palette: {
    baseDarkGray: {
      main: '#6E7687',
      contrastText: '#fff',
    },

    secondaryYellow: {
      main: '#ff8f00',
      contrastText: '#fff',
    },
    secondaryYellowMain: {
      main: '#FFC405',
    },
    darkBlack: {
      main: '#16365f',
      contrastText: '#fff',
    },
  },
  typography: {
    poster: {
      color: 'red',
    },
    // Disable h3 variant
    h5: undefined,
  },
})

const defaultData = {
  borderRadius: 6,
  colorPrimary: '#1677ff',
}

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  if (!isAuthenticated(JSON.parse(localStorage.getItem('authenticated')))) {
    dispatch(logoutUser())
    return <Redirect to="/login" />
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    )
  }
}

const App = (props) => {
  const [data, setData] = React.useState(defaultData)
  localStorage.setItem('idioma', (localStorage.getItem('idioma') === null)?('ES'):(localStorage.getItem('idioma')))
 

  return (
    <div>
      
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            colorPrimary: '#ff8f00',
            borderRadius: data.borderRadius,     
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <HashRouter>
            <Switch>
              <Route
                path="/"
                exact component={LandigPage}
              />
              <Route
                path="/funcionalidades"
                exact component={Funcionalidades}
              />
               <Route
                path="/contacto"
                exact component={Contactenos}
              />
               <Route
                path="/about"
                exact component={Equipo}
              />
              <Route
                path="/template"
                exact
                render={() => <Redirect to="/template/dashboard" />}
              />
              <PrivateRoute
                path="/template"
                dispatch={props.dispatch}
                component={LayoutComponent}
              />
              <Route path="/template/dashboardCouch/:par/:codJe/:codEnc" exact  component={DashboardCouch}/>
              <Route path="/login" exact component={Login} />
              <Route path="/recuperarContraseña" exact component={RecuperarContraseña} />
              <Route path="/error" exact component={ErrorPage} />
              <Route path="/register" exact component={Register} />
              <Route path="/encfin" exact component={Encfinalizada} />
              <Route path="/reporte" exact component={Reporte} />
              <Route path="/reportePlanAccion" exact component={ReportePlanAccion} />
              <Route
                path="/enc/:idEnc/:empresa/:idEmp"
                exact
                component={Encuesta}
              />
              <Route
                path="/encb/:idEnc/:empresa/:idEmp"
                exact
                component={Encuestaburn}
              />
              <Route component={ErrorPage} />
              <Route
                path="*"
                exact={true}
                render={() => <Redirect to="/error" />}
              />
            </Switch>
          </HashRouter>
        </ThemeProvider>
      </ConfigProvider>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(App)
