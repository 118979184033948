import ListaEmpresas from './empresas/ListaEmpresas'
import ListaEmpleados from './Empleados/ListaEmpleados'

const MantenimientoRoutes = [
  {
    path: `/template/empresas`,
    component: ListaEmpresas,
  },
  {
    path: `/template/empleado`,
    component: ListaEmpleados,
  },
]

export default MantenimientoRoutes
