import React, {useState} from "react";
import { 
    Form,
    Input,
    Button,
    Col,
    Row,
    Select,
    Alert,

    
  } from 'antd';
import Api from '../../../Api';
import Swal from 'sweetalert2';


const { Option } = Select;
const { TextArea } = Input;

const CreacionEmpresa = ({handleClose}) => {

    const [form] = Form.useForm();
    
    const [msgAlert, setMsgAlert] = useState('Alerta de prueba');
    
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [disableInput, setdisableInput] = useState(false);

    const onFinish = async (values) => {
       //console.log(values.nombreEmpresa)
       //return
        try {
            const postUser_query = `
            mutation {
                empresaAd(input: { EMPRESA: "${values.nombreEmpresa}"}) {
                    CREATEAT
                    EMPRESA
                    ID
                    LOGO
                    STATUS
                    UPDATEAT
                }
              }
              `;

            const postUser_values = {
                "input": {
                    "EMPRESA": values.nombreEmpresa,
                    
                }
            }


            const response = await Api.getInstance().executeMutation(postUser_query, postUser_values)
            const { empresaAd } = response
           //(empresaAd)
            if (parseInt(empresaAd[0].ID) > 0) {
                Swal.fire({
                    title: 'Información',
                    text: 'Empresa creada exitosamente',
                    icon: 'success',
                    confirmButtonColor: '#ff8f00',
                    confirmButtonText: 'Ok'

                })
                handleClose()
            
            } else {
                Swal.fire({
                    title: 'Advertencia',
                    text: 'Ha ocurrido un error no se creo la empresa',
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }

        } catch (e) {
            console.log(e)

        }

    };
    const validateMessages = {
        required: '${label} es un campo requerido',
    };
    const formItemLayout = {
      
    };

    const Cancelar = () =>{
      /*  setdisableInput(false)
        setdataSourceAprox([])
        setShowTablaAprox(false)
        setshowLoad(false)
        setcargaCompleto(false)
        form.resetFields()*/
    }

  return (
    <div className="ml-4 pl-4 mr-4 pr-4">
        <Form 
            form={form}
            name="nest-messages" 
            onFinish={onFinish} 
            validateMessages={validateMessages}
            layout="vertical"
        >
             <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                    <Form.Item
                        {...formItemLayout}
                        name={['nombreEmpresa']}
                        label="Nombre de empresa"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input 
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                    
                        {
                            (showAlert === true)?(
                                <Alert

                                    message="Alerta"
                                    description={msgAlert}
                                    type="warning"
                                    showIcon
                                    closable
                                />
                            ):(
                                <div></div>
                            )
                        }
                    
                </Col>
                
                <Col  xs={24} md={24} lg={12} xl={12}>
                    <Form.Item
                        >
                        <Button type="primary" block htmlType='submit' loading={loading} >Crear</Button>
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12} xl={12} >
                    <Form.Item
                        >
                        <Button type="default"  loading={loading} onClick={(event) => handleClose()} block  >Cancelar</Button>
                    </Form.Item>
                </Col>
            </Row>
            
            
        </Form>
    </div>
  );
};

export default CreacionEmpresa;
