import React from 'react';
import icono from "../../assets/logoempo.png";

class SofiaLogo extends React.Component {


  render() {
    return (
      <img src={icono} alt="Empowerment"width={200} height={100}/>
    );
  }
}

export default SofiaLogo;
