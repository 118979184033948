import React, { useEffect, useState, Usequ } from "react";
import { v4 as uuidv4 } from "uuid";
import{
Drawer,
Card,
Space,
Table,
Tag,
Tooltip,
Badge,
Button,
Skeleton,
Calendar
} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { gql, selectHttpOptionsAndBodyInternal, useQuery, useMutation } from '@apollo/client'
import AgregarTarea from "./AgregarTarea";
import ListadoTareas from "./ListadoTareas";
import Swal from 'sweetalert2';
import { tokenDatos } from '../../conf/token';
import {
  QUERY_STRING_GET_EMPRESAS
} from '../../gpl/mantenimientoEmpresas';
import { calendarioMuta } from '../../conf/mutation';


const GET_EMPRESAS = gql`${QUERY_STRING_GET_EMPRESAS}`;



const Calendario = () => {
 
    dayjs.locale('es');
    const [getCalendario] = useMutation(calendarioMuta);
    
  const { loading, data, refetch } = useQuery(gql`
  query{ 
      empresasAll {
          created_at
          empresa
          estado
          id
          idemp
          updated_at
      }
  }`);
  const [openCrearTarea, setOpenCrearTarea] = React.useState(false);
  const [openDia, setOpenDia] = useState(false);
  const [fechaDia, setFechaDia] = useState('');
  const [fecha, setFecha] = useState(false)

  const [dataLogin] = useState(tokenDatos())
  const [dataDiasCalendario, setDataDiasCalendario] = useState([])
  //const [dataEmpresas, setDataEmpresas] = useState([]);
   
  const CloseDrawer = () => {
    //console.log('cierre crear empresa')
    setOpenCrearTarea(false);
    setOpenDia(false)
    refetch()
  }
  
  const AbrirCrearTarea = (newOpen) => () => {
    setOpenCrearTarea(newOpen);
  };
  const AbrirVerDia = (newOpen) => () => {
    setOpenDia(newOpen);
  };
  const dataCalendario = async () => {
    const {data} = await getCalendario(
      {
        variables:{
          input: { ID: String(dataLogin.CODEMP)}
        }
      }
    )

    let json = JSON.parse(data.calendario.mensaje)
    console.log('calendario: ', json)
    setDataDiasCalendario(json.respuesta)
  }
  const getListData = (value) => {
    let listData=[];
    //console.log('',value.date(), '/',value.month(), '/', value.year())
    //console.log(dataDiasCalendario)
    let data = ""
    dataDiasCalendario.map((item, index2) => (
      
      (parseInt(item.ANIOFIN) === value.year() && parseInt(item.MESFIN) === (value.month()+1) && parseInt(item.DIAFIN) === value.date()) ?
      
        listData.push(
          {
            type: item.ESTADO === 0 ? 'warning' : 'success',
            content: item.PUNTO,
          }
          
        )
      :item
    ))
    /*if(value.year() === 2023 && (value.month()+1) === 3 && value.date() === 7) {
      listData.push(
        {
          type: 'success',
          content: 'Ingresos  justos',
        }
      )
    }*/

     
 
    return listData || [];
  };
  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    //console.log('entro a mes')
    return num ? (
      <div className="notes-month">
        
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData(value);
    //console.log('date render: ', value)
    //console.log(listData)
    return (
      <ul className="events">
        {listData.map((item) => (
          
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}


      </ul>
    );
  };
  const seleccionDia = (value,  info) => {
    if(info.source === 'date'){
      console.log(info)
      let dia = value.$D
      let mes = value.$M + 1
      let anio = value.$y
  
      let fecha = `${anio}/${mes}/${dia}`
      let fechaD = `${dia}/${mes}/${anio}`
      setFechaDia(fechaD)
      //console.log(fecha)
      setFecha(fecha)
      setOpenDia(true)
    }
    
    /*const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );*/
  };
useEffect(() => {
  dataCalendario()

}, [])



  return (
    <div>
      <div className="headline-2 mb-3">Calendario</div>
      {
        (loading )?
        (<Skeleton></Skeleton>):
        (
          <Card>
            <div className="mb-4">
              <Button type='primary' onClick={AbrirCrearTarea(true)}>Crear reunión</Button>
            </div>
            <Calendar  
              dateCellRender={dateCellRender} 
              monthCellRender={monthCellRender} 
              onSelect={seleccionDia}
            />
           
          </Card>
        )
      }
       {/**/}
      {
        (openCrearTarea === true) && (
            <Drawer
              title={'Creación de reunión'}
              placement="right"
              size={'large'}
              width={'60%'}
              onClose={CloseDrawer}
              open={openCrearTarea}
            >
              {
                  openCrearTarea && (
                    <AgregarTarea 
                      handleClose={CloseDrawer} 
                    ></AgregarTarea>
                  )
              }
            </Drawer>
        )
      }
      {
        (openDia === true) && (
            <Drawer
              title={`Tareas del día ${fechaDia}`}
              placement="right"
              size={'large'}
              width={'60%'}
              onClose={CloseDrawer}
              open={openDia}
            >
              {
                  openDia && (
                    <ListadoTareas 
                      fecha={fecha}
                      handleClose={CloseDrawer} 
                    ></ListadoTareas>
                  )
              }
            </Drawer>
        )
      }
      

    </div>
  )
}

export default Calendario;
