import React, { useState, useEffect, useRef} from 'react';
import json2xls from 'json2xls';
import * as FileSaver from 'file-saver';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {
  Col,
  Row,
} from 'reactstrap';

import { 
  Table,
  Input,
  Space,
  Button,
  Tooltip
} from 'antd';
import { 
  SearchOutlined,  
  SendOutlined
} from '@ant-design/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { tokenDatos } from '../../conf/token';
import Highlighter from 'react-highlight-words';
import Widget from '../../components/Widget/Widget.js'
import ApexActivityChart from './components/ActivityChart.js'
import { useMutation } from '@apollo/client'
import gymIcon from '../../assets/dashboard/gymIcon.svg'
import therapyIcon from '../../assets/dashboard/therapyIcon.svg'
import statsPie from '../../assets/dashboard/statsPie.svg'
import bagIcon from "../../assets/tables/bagIcon.svg";
import s from './DashboardAdmin.module.scss'
import { PERFALTANTES, ReporteAvances } from '../../conf/mutation';
import UsuariosPendientes from './components/UsuariosPendientes.js';
import NivelCompromiso from './components/NivelCompromiso.js';
import { useHistory, useLocation} from 'react-router-dom';



const Empadmin = () => {
  let history = useHistory();
  const [RptAvances] = useMutation(ReporteAvances)
  const searchInput = useRef(null);
  const [dataLogin, setDataLogin] = useState(tokenDatos())
  const [checkboxes, setCheckboxes] = useState([true, false])
  const [Data, setData] = useState([])
  const [Nombre, setNombre] = useState([])
  const [Puesto, setPuesto] = useState([])
  const [searchText, setSearchText] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [faltantes] = useMutation(PERFALTANTES)


  const toggleCheckbox = (id) => {
    setCheckboxes((checkboxes) =>
      checkboxes.map((checkbox, index) =>
        index === id ? !checkbox : checkbox,
      ),
    )
  }


  const getDatos = async () => {   
    const { data } = await faltantes({
      variables: {
        input: { ID: dataLogin.CODIGOEMPRESA },
      },
    })
    setData(data.emprefal)
    //console.log(data.emprefal)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Ingrese texto para buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Borrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const cancel = () => {
    setEditingKey('');
  };
  const HandleReenvio = async(record) => {
    console.log(record)
    try{
      const response = await axios.get(`http://137.184.217.236:3025/email/${record.EMAILEMP}/${record.NOMBRE}/${record.NOMGE}/${dataLogin.ENCUESTANO}/${dataLogin.CODIGOEMPRESA}/${record.CODEMP}`)
      const { data } = response
      console.log(data)
      Swal.fire({
        title: 'Información',
        text: 'Reenvio de correo exitoso',
        icon: 'success',
        confirmButtonColor: '#ff8f00',
        confirmButtonText: 'Ok'
    })
  }catch(e){
    console.log(e)
    Swal.fire({
      title: 'Error',
      text: 'Ha ocurrido un error, no se reenvio correo',
      icon: 'error',
      confirmButtonText: 'Ok'
  })
  }
 
}  

  const ReenviarCorreo = (record) =>{
    return(
        <Tooltip title="Reenviar correo">
            <Button type='primary' shape="circle" icon={<SendOutlined />} onClick={() => HandleReenvio(record)} />
        </Tooltip>
    );
}
  const columns = [

    {
      title: 'Acción',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      render: (text, record) => (
          <Space >
              {ReenviarCorreo(record)}
          </Space>
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'NOMBRE',
      key: 'NOMBRE',
      ...getColumnSearchProps('NOMBRE'),
    },
    {
      title: 'Email',
      dataIndex: 'EMAILEMP',
      ...getColumnSearchProps('EMAILEMP'),
    },
    {
      title: 'Puesto',
      dataIndex: 'PUESTO',
      ...getColumnSearchProps('PUESTO'),
    },
    {
      title: 'Jefe',
      dataIndex: 'NOMGE',
      ...getColumnSearchProps('NOMGE'),
    },
  ]

  const handleChange = (value) => {
    //console.log(`selected ${value}`)
  }
  const json = [
    { nombre: 'Juan', apellido: 'Perez', edad: 25 },
    { nombre: 'María', apellido: 'Gomez', edad: 30 },
    { nombre: 'Carlos', apellido: 'Rodriguez', edad: 35 }
  ];
  
  const handleClick = async (e) => {
    try{
      const { data } = await RptAvances({
        variables: {
          input: { ID: dataLogin.CODIGOEMPRESA },
        },
      })
    
      console.log('resp RPT: ',JSON.parse(data.repavance.mensaje))
      let dataJson = JSON.parse(data.repavance.mensaje)
      
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('My Sheet');
      worksheet.columns = [
          { header: 'Código empleado', key: 'CODEMP', width: 20 },
          { header: 'Nombre', key: 'NOMBRE', width: 40 },
          { header: 'Puesto', key: 'PUESTO', width: 35 },
          { header: 'Email', key: 'EMAIL', width: 35 },
          { header: 'Sucursal', key: 'SUCURSAL', width: 25 },
          { header: 'Región', key: 'REGION', width: 25 },
          { header: 'Estado', key: 'ESTADO', width: 25 },
          { header: 'Fecha', key: 'FECHA', width: 25 },
          { header: 'Código jefe', key: 'CODIGOJEFE', width: 15 },
          { header: 'Nombre jefe', key: 'NOMBREJEFE', width: 40 },
          { header: 'Email jefe', key: 'EMAILJEFE', width: 35 },
          { header: 'Puesto jefe', key: 'PUESTOJEFE', width: 35 },
      ];
      dataJson.respuesta.map((item, index2) => (
        worksheet.addRow(item)
       
      ))   
      workbook.xlsx.writeBuffer().then(function (data) {
        var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        saveAs(blob, 'reporte-avances.xlsx');
    });
      //console.log(dataTotalResp)
    }catch(ex){
      console.log(ex)
    }
    
    
    //const xls = exportToExcel(samplejson2, 'downloadfilename')s
    //const xls = json2xls(json);
    //res.xls('data.xlsx', jsonArr, options);
    //const blob = new Blob([workbook], { type: 'application/vnd.ms-excel' });
    //FileSaver.saveAs(new Blob([xls], { type: 'application/octet-stream' }), 'ListadoOportunidad.xlsx');
    //FileSaver.saveAs(blob, 'data.xlsx');
  };
  useEffect(() => {
    
    if(dataLogin.ROL === 'ADMINCOUCH'){
      history.push(`/template/dashboard`)
      
    }
    getDatos()
    
  }, [])
  return (
    <div>
      <div className="headline-2 mb-3">Dashboard administrador</div>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={8}>
          <Row className="gutter mb-4">
            <Col className="mb-4 mb-md-0" xs={12} md={12}>
              <Widget className="">
                <div className="d-flex justify-content-between widget-p-md">
                  <div className="headline-3 d-flex align-items-center">
                    Avances por departamentos
                  </div>                  
                </div>
                <ApexActivityChart empleado={String(dataLogin.CODIGOEMPRESA)} encuesta={dataLogin.ENCUESTANO} className="pb-4" />
              </Widget>
            </Col>
            {/* <Col xs={12} md={6}>
              <Widget className="widget-p-md">
                <div className="d-flex justify-content-between">
                  <div className="headline-3 d-flex align-items-center">
                    Contestadas
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>&nbsp; Weekly &nbsp;</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>Daily</DropdownItem>
                      <DropdownItem>Weekly</DropdownItem>
                      <DropdownItem>Monthly</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                {meals.map((meal) => (
                  <div key={uuidv4()} className={`mt-4 ${s.widgetBlock}`}>
                    <div className={s.widgetBody}>
                      <div className="d-flex">
                        <img className="img-fluid mr-2" src={meal} alt="..." />
                        <div className="d-flex flex-column">
                          <p className="body-2">Salmon salad</p>
                          <p className="body-3 muted">300 g</p>
                        </div>
                      </div>
                      <div className="body-3 muted">175 cal</div>
                    </div>
                  </div>
                ))}
              </Widget>
            </Col> */}
          </Row>
          <Row>
            <Col className="mb-4 mb-md-0" xs={12} md={12}>
                <Widget className="widget-p-md centrado">
                  <p className="headline-3">Empleados faltantes</p>
                  <Table
                    columns={columns}
                    dataSource={Data}
                    scroll={{ x: 500, y: 300 }}
                  />
                </Widget>
              </Col>
          </Row>
          <Row className="gutter mb-4">
            <Col className="mb-4 mt-3 mb-md-0" xs={12} md={12} lg={8} xl={8}>
              <Widget className="widget-p-md centrado">
                <p className="headline-3">Grafica de encuestas contestadas</p>
                <UsuariosPendientes idEmpresa = {dataLogin.CODIGOEMPRESA}></UsuariosPendientes>
              </Widget>
            </Col>
           </Row>
          
          <Row className="gutter">
            {/* <Col className="mb-4 mb-xl-0" xs={6} sm={6} xl={3}>
              <Widget className="widget-p-sm">
                <div className={s.smallWidget}>
                  <div className="d-flex mb-4">
                    <img
                      className="py-1 mr-2 img-fluid"
                      src={heartRed}
                      alt="..."
                    />
                    <div className="d-flex flex-column">
                      <p className="headline-3">Text</p>
                      <p className="body-2">
                        Num<span className="body-3 muted">/ ber</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <Progress
                      color="secondary-red"
                      className={`progress-xs ${s.mutedPink}`}
                      value="75"
                    />
                  </div>
                </div>
              </Widget>
            </Col> */}
            {/* <Col className="mb-4 mb-xl-0" xs={6} sm={6} xl={3}>
              <Widget className="widget-p-sm">
                <div className={s.smallWidget}>
                  <div className="d-flex mb-4">
                    <img
                      className="py-1 mr-2 img-fluid"
                      src={heartYellow}
                      alt="..."
                    />
                    <div className="d-flex flex-column">
                      <p className="headline-3">Text</p>
                      <p className="body-2">
                        Num<span className="body-3 muted">/ ber</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <Progress
                      color="secondary-yellow"
                      className={`progress-xs ${s.mutedYellow}`}
                      value="75"
                    />
                  </div>
                </div>
              </Widget>
            </Col> */}
            {/* <Col xs={6} sm={6} xl={3}>
              <Widget className="widget-p-sm">
                <div className={s.smallWidget}>
                  <div className="d-flex mb-4">
                    <img
                      className="py-1 mr-2 img-fluid"
                      src={heartTeal}
                      alt="..."
                    />
                    <div className="d-flex flex-column">
                      <p className="headline-3">Text</p>
                      <p className="body-2">
                        Num<span className="body-3 muted">/ ber</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <Progress
                      color="secondary-cyan"
                      className={`progress-xs ${s.mutedTeal}`}
                      value="75"
                    />
                  </div>
                </div>
              </Widget>
            </Col>
            <Col xs={6} sm={6} xl={3}>
              <Widget className="widget-p-sm">
                <div className={s.smallWidget}>
                  <div className="d-flex mb-4">
                    <img
                      className="py-1 mr-2 img-fluid"
                      src={heartViolet}
                      alt="..."
                    />
                    <div className="d-flex flex-column">
                      <p className="headline-3">Text</p>
                      <p className="body-2">
                        Num<span className="body-3 muted">/ ber</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <Progress
                      color="violet"
                      className={`progress-xs ${s.mutedViolet}`}
                      value="75"
                    />
                  </div>
                </div>
              </Widget>
            </Col> */}
          </Row>
        </Col>
        <Col className="mt-4 mt-lg-0 pl-grid-col" xs={12} lg={4}>
          <Widget className="widget-p-lg">
            <div className="d-flex">
            <div><img src={bagIcon} alt="Item" /><span className="body-2 ml-3"></span></div>
              <div className={s.userInfo}>
                <p className="headline-3 mb-5">{dataLogin.NOMBREEMPRESA}</p>
              </div>
            </div>
            <NivelCompromiso codigoEmp = {String(dataLogin.CODIGOEMPRESA)} idEncuesta = {dataLogin.ENCUESTANO}></NivelCompromiso>
            
            <a
              className={`btn-secondary-red ${s.statsBtn}`}
              onClick={handleClick}
              role="button"
            >
              <img className={s.pieImg} src={statsPie} alt="..." />
              <div>
                <p className="headline-2">AVANCES</p>
                <p className="body-3">Descargar</p>
              </div>
            </a>
          </Widget>
        </Col>
      </Row>
    </div>
  )
}

export default Empadmin
