import React, {useState, useEffect} from "react";
import { 
    Card,
    Form,
    Descriptions,
    Badge,
    Space,
    Skeleton
  } from 'antd';
  import { useMutation } from '@apollo/client';
  import { tokenDatos } from '../../conf/token';
  import { calendarioDiaDet } from '../../conf/mutation';


const ListadoTareas = ({handleClose, fecha}) => {

    const [form] = Form.useForm();
    
    const [msgAlert, setMsgAlert] = useState('Alerta de prueba');
    const [getCalendario] = useMutation(calendarioDiaDet);
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [disableInput, setdisableInput] = useState(false);
    const [dataLogin] = useState(tokenDatos())
  
    const [dataDiasCalendario, setDataDiasCalendario] = useState([])

    const onFinish = async (values) => {
       
        
    };
    const validateMessages = {
        required: '${label} es un campo requerido',
    };
    const formItemLayout = {
      
    };
   const listData = [
        {
            
          type: 'yellow',
          content: 'Plan de acción proximo a vencer.',
          titulo: 'Próximo a vencer'
        },
        {
          type: 'green',
          content: '5 planes de acción finalizados.',
          titulo: 'Finalizado'
        },
        {
            type: 'blue',
            content: 'Proxima encuesta en 5 días',
            titulo: 'Encuesta'
        },
        {
            type: 'red',
            content: 'Plan de acción vencido',
            titulo: 'Vencido'
        },
      ];
    const dataDia = async () => {
      try{
        const {data} = await getCalendario(
          {
            variables:{
              input: { ID: String(dataLogin.CODEMP), FEC: fecha}
            }
          }
        )
    
        let json = JSON.parse(data.calendariodet.mensaje)
        //console.log('calendario: ', json)
        setDataDiasCalendario(json.respuesta)
        setLoading(false)
  
      }catch(ex){
        console.log(ex)
      }
      
    }
    useEffect(() => {
      dataDia()
    }, [])
  return (
    <div className="ml-4 pl-4 mr-4 pr-4">
      {
        (loading )?
        (<Skeleton></Skeleton>):
        (
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            {dataDiasCalendario.map((item) => (
                <Badge.Ribbon  key={item.ID} text={item.ESTADO === 0 ? 'Pendiente' : 'Finalizado'} color={item.ESTADO === 0 ? 'yellow' : 'green'}>
                    <Card title="" size="small">
                      <Descriptions title="">
                        <Descriptions.Item label="Tipo" span={3}>{item.TIPO}</Descriptions.Item>
                        <Descriptions.Item label="Punto" span={3}>{item.PUNTO}</Descriptions.Item>
                        <Descriptions.Item label="Objetivo" span={3}>{item.OBJETIVO}</Descriptions.Item>
                        <Descriptions.Item label="Descripción" span={3}>{item.DESCRIP}</Descriptions.Item>
                      </Descriptions>
                    </Card>
                </Badge.Ribbon>
            ))}
            
        </Space>
        )
      }
        
    </div>
  );
};

export default ListadoTareas;
