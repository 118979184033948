import React, { useState, useEffect }  from "react";
import{
  Skeleton,
  } from 'antd';
import { encRadarCouch } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Radar } from '@ant-design/plots';

const ResultadosRadar = ({codigoEmp, idEncuesta}) => {
  const [encRadar] = useMutation(encRadarCouch)
  const [load, setLoad] = useState(true)
  const [data, setdata] = useState([]);

  const dataDash = [
    {
      name: 'Autorrealización',
      Resultado: 5,
    },
    {
      name: 'Pertenencia',
      Resultado: 6,
    },
    {
      name: 'Dirección y objetivos ',
      Resultado: 6,
    },
    {
      name: 'Incentivos \n y motivación',
      Resultado: 6,
    },
   
  ];
  

  const config = {
    data: data,
    xField: 'name',
    yField: 'Resultado',
    appendPadding: [30, 30, 30, 30],
    meta: {
      Resultado: {
        alias: '',
        min: 0,
        max: 10,
        nice: true,

      },
    },
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
   
    point: {
      size: 1,
    },
    area: {},
    color: '#FF8F00'
  
  };
  const getTotalEnc = async () => {
    //let codEmp = localStorage.getItem('emp')
    try{
        //console.log(codigoEmp, ' ', idEncuesta)
      
      const {data} = await encRadar(
        {
          variables:{
            input: { CODJE: String(codigoEmp), ENC: idEncuesta}
          }
        }
      )
      
      let dataJson = JSON.parse(data.punteo.mensaje)
      //console.log(dataJson)
      //console.log('resultado radar: ',dataJson)
      let op = [
        {
          name: dataJson.dataDash[0].name,
          Resultado: parseInt(dataJson.dataDash[0].Resultado)
        },
        {
          name: dataJson.dataDash[3].name,
          Resultado: parseInt(dataJson.dataDash[3].Resultado)
        },
        {
          name: dataJson.dataDash[1].name,
          Resultado: parseInt(dataJson.dataDash[1].Resultado)
        },
        {
          name: 'Incentivos \n y motivación',
          Resultado: parseInt(dataJson.dataDash[2].Resultado)
        },
      ]
      setdata(op)
      //setLabel(dataJson[2].dataLabel)
      setLoad(false)

      
    }catch(ex){
      
      console.log(ex)
    }
    //console.log(op)
  }
  useEffect(() => {
    //console.log(load)
    getTotalEnc()
  }, [])
  return (
    <>
    {
      (load)?
      ( 
        <Skeleton className="mt-5"></Skeleton>
      ):
      (
        <div className="">
          {/*<div className="my-3 body-3 muted">Different types of notifications for lots of use cases. Custom classes</div>*/}
          <Radar {...config} />
        </div>
      )
    }
    </>
  );
};

export default ResultadosRadar;
