import React, { useState, useEffect } from "react";
import { Heatmap } from '@ant-design/plots';
import {
  Space, 
  Table,
  Badge,
  Tag,
  Row,
  Col,
  Skeleton,
  Divider,
  Card
} from 'antd'
import { UserOutlined } from '@ant-design/icons';
import s from "../Dashboard.module.scss";
import ResultadosRadarDetalle from "./ResultadosRadarDetalle";
import { encPreguntasRes } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';



const DetalleResultado = ({tipo, codigoEmp, idEncuesta, idEmpresa}) => {

  const [showLoad, setShowLoad] = useState(true)
  const [dataEquipo, setDataEquipo] = useState([]);
  const [encuestaPreguntas] = useMutation(encPreguntasRes);
  const [rows, setRows] = useState([])
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)

  //console.log(tipo)
  const getPreguntasEnc = async () => {
    let id = localStorage.getItem('emp')
    //console.log(id)
  const rows = [
    {
      persona: `Persona 1`,
        pregunta: `Pregunta 1`,
        value: 8,
    },
    {
      persona: `Persona 1`,
        pregunta: `Pregunta 2`,
        value: 7,
    },
    
    {
      persona: `Persona 1`,
        pregunta: `Pregunta 3`,
        value: 1,
    },
    
    {
      persona: `Persona 1`,
        pregunta: `Pregunta 4`,
        value: 4,
    },
    
    {
      persona: `Persona 1`,
        pregunta: `Pregunta 5`,
        value: 10,
    },
    {
      persona: `Persona 1`,
        pregunta: `Pregunta 6`,
        value: 10,
    },
    {
      persona: `Persona 2`,
        pregunta: `Pregunta 1`,
        value: 5,
    },
    {
      persona: `Persona 2`,
        pregunta: `Pregunta 2`,
        value: 6,
    },
    
    {
      persona: `Persona 2`,
        pregunta: `Pregunta 3`,
        value: 3,
    },
    
    {
      persona: `Persona 2`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 2`,
        pregunta: `Pregunta 5`,
        value: 1,
    },
    {
      persona: `Persona 2`,
        pregunta: `Pregunta 6`,
        value: 5,
    },
    {
      persona: `Persona 3`,
        pregunta: `Pregunta 1`,
        value: 5,
    },
    {
      persona: `Persona 3`,
        pregunta: `Pregunta 2`,
        value: 6,
    },
    
    {
      persona: `Persona 3`,
        pregunta: `Pregunta 3`,
        value: 8,
    },
    
    {
      persona: `Persona 3`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 3`,
        pregunta: `Pregunta 5`,
        value: 2,
    },
    {
      persona: `Persona 3`,
        pregunta: `Pregunta 6`,
        value: 8,
    },
    {
      persona: `Persona 4`,
        pregunta: `Pregunta 1`,
        value: 6,
    },
    {
      persona: `Persona 4`,
        pregunta: `Pregunta 2`,
        value: 6,
    },
    
    {
      persona: `Persona 4`,
        pregunta: `Pregunta 3`,
        value: 8,
    },
    
    {
      persona: `Persona 4`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 4`,
        pregunta: `Pregunta 5`,
        value: 2,
    },
    {
      persona: `Persona 4`,
        pregunta: `Pregunta 6`,
        value: 3,
    },
    {
      persona: `Persona 5`,
        pregunta: `Pregunta 1`,
        value: 6,
    },
    {
      persona: `Persona 5`,
        pregunta: `Pregunta 2`,
        value: 10,
    },
    
    {
      persona: `Persona 5`,
        pregunta: `Pregunta 3`,
        value: 8,
    },
    
    {
      persona: `Persona 5`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 5`,
        pregunta: `Pregunta 5`,
        value: 2,
    },
    {
      persona: `Persona 5`,
        pregunta: `Pregunta 6`,
        value: 3,
    },
    {
      persona: `Persona 6`,
        pregunta: `Pregunta 1`,
        value: 6,
    },
    {
      persona: `Persona 6`,
        pregunta: `Pregunta 2`,
        value: 2,
    },
    
    {
      persona: `Persona 6`,
        pregunta: `Pregunta 3`,
        value: 8,
    },
    
    {
      persona: `Persona 6`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 6`,
        pregunta: `Pregunta 5`,
        value:7,
    },
    {
      persona: `Persona 6`,
        pregunta: `Pregunta 6`,
        value: 9,
    },
    {
      persona: `Persona 7`,
        pregunta: `Pregunta 1`,
        value: 1,
    },
    {
      persona: `Persona 7`,
        pregunta: `Pregunta 2`,
        value: 9,
    },
    
    {
      persona: `Persona 7`,
        pregunta: `Pregunta 3`,
        value: 3,
    },
    
    {
      persona: `Persona 7`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 7`,
        pregunta: `Pregunta 5`,
        value: 2,
    },
    {
      persona: `Persona 7`,
        pregunta: `Pregunta 6`,
        value: 3,
    },{
      persona: `Persona 8`,
        pregunta: `Pregunta 1`,
        value: 6,
    },
    {
      persona: `Persona 8`,
        pregunta: `Pregunta 2`,
        value: 6,
    },
    
    {
      persona: `Persona 8`,
        pregunta: `Pregunta 3`,
        value: 8,
    },
    
    {
      persona: `Persona 8`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 8`,
        pregunta: `Pregunta 5`,
        value: 5,
    },
    {
      persona: `Persona 8`,
        pregunta: `Pregunta 6`,
        value: 9,
    },
    {
      persona: `Persona 9`,
        pregunta: `Pregunta 1`,
        value: 2,
    },
    {
      persona: `Persona 9`,
        pregunta: `Pregunta 2`,
        value: 6,
    },
    
    {
      persona: `Persona 9`,
        pregunta: `Pregunta 3`,
        value: 8,
    },
    
    {
      persona: `Persona 9`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 9`,
        pregunta: `Pregunta 5`,
        value: 10,
    },
    {
      persona: `Persona 9`,
        pregunta: `Pregunta 6`,
        value: 6,
    },
    {
      persona: `Persona 10`,
        pregunta: `Pregunta 1`,
        value: 6,
    },
    {
      persona: `Persona 10`,
        pregunta: `Pregunta 2`,
        value: 6,
    },
    
    {
      persona: `Persona 10`,
        pregunta: `Pregunta 3`,
        value: 8,
    },
    
    {
      persona: `Persona 10`,
        pregunta: `Pregunta 4`,
        value: 5,
    },
    
    {
      persona: `Persona 10`,
        pregunta: `Pregunta 5`,
        value: 2,
    },
    {
      persona: `Persona 10`,
        pregunta: `Pregunta 6`,
        value: 9,
    },
    ];
    //setData(rows)
    //return
    try{
      const {data} = await encuestaPreguntas(
          {
            variables:{
              input: { CODJE: codigoEmp, ENC: idEncuesta, EMP: idEmpresa, TIP: tipo}
            }
          }
        )
    
      
        let preguntas = []
    
        let json = JSON.parse(data.getpunteoequipo.mensaje)
        console.log('punteo equipo', json)
        let persona = ''
    if(tipo === 'Pertenencia'){
      let count = 0
      json.respuesta.map((item, index) => {
      
        item.data.map((item2, index2) => (
          index2 !== 5 ?
            preguntas.push(
              {
                persona: `Persona ${index + 1}`,
                pregunta: item2,
                value: item2 === null ? 2 : item2,
              }
            )
          :item2
        ))
        
        
      } 
    )
    json.respuesta.map((item, index) => {
      
      item.descrip.map((item2, index2) => {
            
        preguntas[count].pregunta = item2
        count = count + 1
        //console.log(count)
      })

    })
    
    
    }else{
      let count = 0
      json.respuesta.map((item, index) => {
      
        item.data.map((item2, index2) => (
          index2 !== 6 ?
            preguntas.push(
              {
                persona: `Persona ${index + 1}`,
                pregunta: item2,
                value: item2 === null ? 2 : item2,
              }
            )
          :item2
        ))
        
        
      } 
    )
    json.respuesta.map((item, index) => {
      
      item.descrip.map((item2, index2) => {
            
        preguntas[count].pregunta = item2
        count = count + 1
        console.log(count)
      })

    })
    }
        
      console.log(preguntas)
      setData(preguntas)
      setShowLoad(false)
      setLoad(false)
    }catch(ex){
    
        console.log("mapa Resultados: ",ex)
    }

  }


    function createData(persona, p1, p2, p3, p4, p5, p6) {
        return { persona, p1, p2, p3, p4, p5, p6 };
      }
    const Estado = (record) => {
      //console.log(record)
        if(record >= 8 && record <= 10){
          return (
            <Tag
              style={{
                height: '100px',
                width: '100px',
              }} 
             color="#87d068"
            ></Tag>
          )
        }
        if(record >= 5 && record <= 7){
          return (
            <Tag 
              color="#faad14"
              style={{
                height: '100px',
                width: '100px',
              
              }} 
            ></Tag>
          )
        }
        if(record >=1 && record <= 4){
          return (
              <Tag 
              style={{
                height: '100px',
                width: '100px',
              }} 
                color="#ff4d4f"
              ></Tag>
            )
        }
        
    }
      
    const config = {
      data,
      xField: 'persona',
      yField: 'pregunta',
      colorField: 'value',
      shape: 'circle',
      sizeRatio: 0.3,
      color: ({ value }) => {
        if(value >=1 && value <= 4){
          return '#ff4d4f';
        }
        if(value >= 5 && value <= 7){
          return '#faad14';
        }
        if(value >= 8 && value <= 10){
          return '#87d068';
        }
        
      },
      tooltip: {
        showMarkers: false,
        fields: ['xG conceded', 'Shot conceded'],
      },
      
    };
      const columns = [
        
        {
          title: '',
          dataIndex: 'persona',
          key: 'persona',
          width: 50,
          align: 'center',
          render: (text, record) => (
            <Space >

                <UserOutlined 
                    style={{
                        color: '#ff8f00',
                        fontSize: '25px',
                      }}
                > {record.persona}</UserOutlined>
            </Space>
          ),

          
        },
        {
            title: 'P1',
            dataIndex: 'p1',
            key: 'p1',
            align: 'center',
            render: (_, {p1}) => (
              <>
                {Estado(p1)}
              </>
            ),
        },
        {
            title: 'P2',
            dataIndex: 'p2',
            align: 'center',
            key: 'p2', 
            render: (_, {p2}) => (
              <>
                {Estado(p2)}
              </>
            ), 
        },
        {
          title: 'P3',
          dataIndex: 'p3',
          align: 'center',
          key: 'p3',  
          render: (_, {p3}) => (
            <>
              {Estado(p3)}
            </>
          ),
        },
        {
          title: 'P4',
          dataIndex: 'p4',
          align: 'center',
          key: 'p4',  
          render: (_, {p4}) => (
            <>
              {Estado(p4)}
            </>
          ),
        },
        {
          title: 'P5',
          dataIndex: 'p5',
          align: 'center',
          key: 'p5', 
          render: (_, {p5}) => (
            <>
              {Estado(p5)}
            </>
          ), 
        },
        {
          title: 'P6',
          dataIndex: 'p6',
          align: 'center',
          key: 'p6',  
          render: (_, {p6}) => (
            <>
              {Estado(p6)}
            </>
          ),
        },
       
    ];
    useEffect(() => {
      getPreguntasEnc()
    }, [])
  return (
    <>
      <div className="ml-4 mr-4">
        <ResultadosRadarDetalle empresa={idEmpresa} codigoEmp={codigoEmp} idEncuesta={idEncuesta} tipoResultado = {tipo}></ResultadosRadarDetalle>
      {
        (load)?
        ( 
         
          <Skeleton className="mt-5"></Skeleton>
        
        ):
        (
          
          <div className="mt-5 ">
            <Divider orientation="left" className="mb-3">Mapa de resultados por persona</Divider>
            <Row className="mt-4" gutter={[12, 8]}>
              <Col span={18}>
                <Heatmap {...config} />
              </Col>
              <Col span={6}>
                <Row className="mt-3" gutter={[8, 40]}></Row>
                <Card
                  style={{
                    width: 200,
                  }}
                  className='mt-5 ml-2'
                >
                  <Badge size="small" color="#87d068" text="Estado óptimo" /><br/>
                  <Badge color="#faad14" text="Necesita mejorar" /><br/>
                  <Badge color="#ff4d4f" text="Atención inmediata" /><br/>
                  
                </Card>
                
              </Col>
              <Col span={6}></Col>
            </Row>
          
          </div>
        )
      }
    </div>
    </>
    
  
  );
};

export default DetalleResultado;
