import React, {useState, useEffect} from "react";
import {
    Space, 
    Table,
    Tooltip,
    Button,
    Tag,
  } from 'antd'
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { PlanAccionActivo, PlanAccionFinalizado, FinalizarPlanAccion } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import Swal from 'sweetalert2';


const TablaPlan = ({tipo, tip, codigoEmp, idEncuesta, idEmpresa, consulta, handleClose}) => {

  
    const [planActivo] = useMutation(PlanAccionActivo)
    const [planFin] = useMutation(PlanAccionFinalizado)
    const [OpFinPlan] = useMutation(FinalizarPlanAccion)
    const [dataPlanActivo, setDataPlanActivo] = useState([]);
    const [dataPlanFin, setDataPlanFin] = useState([]);
    const [showLoad, setShowLoad] = useState(true)
    const [msgAlert, setMsgAlert] = useState('Alerta de prueba');

    const dataPla =[
        {
          numero: 1,
          tipo: 'Autorrealización',
          punto: 'Reconocimiento',
          objetivo: 'Motivar e incentivar a los desarrolladores a entregar un producto de calidad',
          desc: 'Trimestralmente se realizara una evaluacion de productividad tomando factores cuantitativos y cualitativos, con los cuales motivaremos al desarrollador a realizar un trabajo mas eficiente y de calidad, para cada evaluacion trimestral se realizara lo siguiente:1. Premiaremos el esfuerzo de los resultados obtenidos en la reunion de entrega de resultados que se programen 2. Se realizara una mension especial a los programadores que cumplieron con los objetivos propuestos, y se les hara entrega de un diploma 3. Se solicitara el apoyo de Gestion del talento para que nos puedan otorgar algun articulo promocional o regalo ( pachones, vasos, pines, gifcards, mousepad, etc.)',
          fecha: '30/09/2022',
          estado: 'Pendiente'
        },
        {
          numero: 2,
          tipo: 'Pertenencia',
          punto: 'Trabajo importante',
          objetivo: 'Motivar e incentivar a los desarrolladores a entregar un producto de calidad',
          desc: 'Trimestralmente se realizara una evaluacion de productividad tomando factores cuantitativos y cualitativos, con los cuales motivaremos al desarrollador a realizar un trabajo mas eficiente y de calidad, para cada evaluacion trimestral se realizara lo siguiente:1. Premiaremos el esfuerzo de los resultados obtenidos en la reunion de entrega de resultados que se programen 2. Se realizara una mension especial a los programadores que cumplieron con los objetivos propuestos, y se les hara entrega de un diploma 3. Se solicitara el apoyo de Gestion del talento para que nos puedan otorgar algun articulo promocional o regalo ( pachones, vasos, pines, gifcards, mousepad, etc.)',
          fecha: '30/09/2022',
          estado: 'Pendiente'
        },
      ]
      const Finalizar = async (key, e)  => {
        try{
          //console.log(parseInt(key.ID))
          const {data} = await OpFinPlan(
            {
              variables:{
                input: { CODJE: String(codigoEmp), IDPL: parseInt(key.ID)}
              }
            }
          )
          const {mensaje} = data.finplanAcc
          
          const dataJson = JSON.parse(mensaje)
          //console.log('finalizar plan de accion ', dataJson)
          if(dataJson.estado === 1){
            handleClose()
            Swal.fire({
                title: 'Información',
                text: 'Plan de acción finalizado exitosamente',
                icon: 'success',
                confirmButtonColor: '#ff8f00',
                confirmButtonText: 'Ok'
            })
          }else{
            Swal.fire({
              title: 'Advertencia',
              text: 'Ha ocurrido un error, no se finalizo el plan de acción',
              icon: 'warning',
              confirmButtonColor: '#ff8f00',
              confirmButtonText: 'Ok'
          })
           
          }

        }catch(e){
          Swal.fire({
            title: 'Advertencia',
            text: 'Ha ocurrido un error, no se finalizo el plan de acción',
            icon: 'warning',
            confirmButtonColor: '#ff8f00',
            confirmButtonText: 'Ok'
        })
          console.log(e)
        }
    }
      const columns2 = [
        {
          title: 'Finalizar',
          dataIndex: 'finalizar',
          key: 'finalizar', 
          fixed: 'left', 
          width: 100,
          render: (text, record) => (
            (consulta === 1)?(
              <></>
            ):(
              <Space >
                  <Tooltip title="Finalizar">
                      <Button 
                        type="primary" 
                        onClick={ () => Finalizar(record)} 
                        icon={<CheckCircleOutlined />}
                      >Finalizar</Button> 
                  </Tooltip>
              </Space>
            )
           
          ),
        },
        {
          title: 'Tipo',
          dataIndex: 'TIPO',
          key: 'TIPO', 
          width: 150,
      },
      {
        title: 'Punto',
        dataIndex: 'PUNTO',
        key: 'PUNTO',
        width: 150,
      },
      {
          title: 'Objetivo',
          dataIndex: 'OBJETIVO',
          key: 'OBJETIVO',
          width: 400,
      },
      {
          title: 'Descripción',
          dataIndex: 'DESCRIP',
          key: 'DESCRIP',  
          width: 500,
      },
      {
        title: 'Fecha',
        dataIndex: 'FECINICIO',
        key: 'FECINICIO',
        width: 150,
      },
      
      {
          title: 'Fecha finaliza',
          dataIndex: 'FECFIN',
          key: 'FECFIN',
          width: 150,
      },
      ];
      const dataPlaFin =[
        {
          numero: 1,
          tipo: 'Direccion y objetivo',
          punto: 'Sé lo que se espera de mi',
          objetivo: 'Aclarar los objetivos y metas del departamento',
          desc: 'Implementar reuniones semanales en donde se determinaran los objetivos de cada miembro del equipo.Tener un control de los procesos de cada uno de los colaboradores.Implementar grupos de chat en la herramienta teams para tener una mejor comunicación entre los colaboradores y lider del equipo.',
          fecha: '30/06/2022 12:00:00 ',
          estado: 'Finalizado',
          fecha_fin: '30/06/2022'

        },
        
      ]
      const columnsFin = [
        
        {
            title: 'Tipo',
            dataIndex: 'TIPO',
            key: 'TIPO', 
            width: 150,
        },
        {
          title: 'Punto',
          dataIndex: 'PUNTO',
          key: 'PUNTO',
          width: 150,
        },
        {
            title: 'Objetivo',
            dataIndex: 'OBJETIVO',
            key: 'OBJETIVO',
            width: 400,
        },
        {
            title: 'Descripción',
            dataIndex: 'DESCRIP',
            key: 'DESCRIP',  
            width: 500,
        },
        {
          title: 'Fecha',
          dataIndex: `FECINICIO`,
          key: 'FECINICIO',
          width: 150,
        },
        
        {
            title: 'Fecha finalizado',
            dataIndex: 'FECFIN',
            key: 'FECFIN',
            width: 150,
        },        
      ];
      const getActivos = async () => {
        try{
          let codEmp = localStorage.getItem('emp')
          const {data} = await planActivo(
            {
              variables:{
                input: {CODJE: String(codigoEmp), EMP: String(idEmpresa), TIP: String(tip), ENC: idEncuesta}
              }
            }
          )
          const {mensaje} = data.planAccion
          //console.log('plan accion activos', data)
          const dataJson = JSON.parse(mensaje)
          //console.log('plan accion', dataJson)
          let dataActivo = []
          dataJson.respuesta.map((item, index) => {
            //console.log(item.FECINICIO)
            dataActivo.push({
              CODEMP: item.CODEMP,
              ID: item.ID,
              TIPO: item.TIPO,
              PUNTO: item.PUNTO,
              FECINICIO: format(item.FECINICIO),
              FECFIN: format(item.FECFIN),
              OBJETIVO: item.OBJETIVO,
              DESCRIP: item.DESCRIP,
              CODEMP: item.CODEMP,
              EMP: item.EMP,
              ENCNO: item.ENCNO,
              FECHA: format(item.FECHA),
              ESTADO: item.ESTADO,
              FECHA_FIN: format(item.FECHA_FIN),
              CODEMP_FIN: item.CODEMP_FIN

            })

        })
          setDataPlanActivo(dataActivo)
          console.log('plan activo:', dataActivo)
          //setDataPlanActivo([])
          setShowLoad(false)
        }catch(e){
          console.log(e)
        }
        
      }
      const getFinalizados = async () => {
        try{
          const {data} = await planFin(
            {
              variables:{
                input: { CODJE: String(codigoEmp), EMP: String(idEmpresa), TIP: String(tip), ENC: idEncuesta}
              }
            }
          )
          //console.log(data)
          const {mensaje} = data.planAccion
          
          const dataJson = JSON.parse(mensaje)
          //console.log('plan finalizado: ', dataJson.respuesta)
          
          let dataFinalizado = []
          dataJson.respuesta.map((item, index) => {
            //console.log(item.FECINICIO)
            dataFinalizado.push({
              CODEMP: item.CODEMP,
              ID: item.ID,
              TIPO: item.TIPO,
              PUNTO: item.PUNTO,
              FECINICIO: format(item.FECINICIO),
              FECFIN: format(item.FECFIN),
              OBJETIVO: item.OBJETIVO,
              DESCRIP: item.DESCRIP,
              CODEMP: item.CODEMP,
              EMP: item.EMP,
              ENCNO: item.ENCNO,
              FECHA: format(item.FECHA),
              ESTADO: item.ESTADO,
              FECHA_FIN: format(item.FECHA_FIN),
              CODEMP_FIN: item.CODEMP_FIN

            })

        })
        
          setDataPlanFin(dataFinalizado)

         /* let dataF = [{
            CODEMP: 0,
            ID: 0,
            TIPO: 'Incentivos y motivación',
            PUNTO: 'Ingresos justos',
            FECINICIO: '07/03/2023',
            FECFIN: '10/03/2023',
            OBJETIVO: 'Mejorar la satisfacción de los ingresos',
            DESCRIP: 'Se generará un programa de incentivos variables atados a los resultados cualitativos y cuantitativos del equipo.',
            CODEMP: 0,
            EMP: 0,
            ENCNO: 0,
            FECHA: '07/03/2023',
            ESTADO: '',
            FECHA_FIN: '10/03/2023',
            CODEMP_FIN: 0

          }]
          setDataPlanFin(dataF)*/
          setShowLoad(false)
        }catch(e){
          console.log(e)
        }
          
      }
      function format(inputDate) {
        
        let date, month, year;
        const datePlan = new Date(inputDate);
        //console.log(datePlan)
        date = datePlan.getDate();
        month = datePlan.getMonth()+1;
        year = datePlan.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${date}/${month}/${year}`;
      }
      useEffect(() => {
        getActivos()
        getFinalizados()
      }, [])
 
  return (
    <div >
      
      <Table 
            loading={showLoad} 
            columns={(tipo === 1) ? (columns2) : (columnsFin)} 
            dataSource={(tipo === 1) ? (dataPlanActivo) : (dataPlanFin)} 
            pagination={true} 
    
            bordered
        />
    </div>
  );
};

export default TablaPlan;
