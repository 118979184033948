import React, { useState, useEffect } from "react";
import{
  Skeleton
} from 'antd';
import {
  Col,
  Row,
  Collapse
} from "reactstrap";
import { 
    Button,
    Stack,
  } from '@mui/material';
  import { 
    encPunteoMP4
  } from '../../../conf/mutation';
  
import s from "../Dashboard.module.scss";
import {useMutation } from '@apollo/client';
import PreguntaAbierta from "./PreguntaAbierta";
import QuizIcon from '@mui/icons-material/Quiz';
import NivelCompromiso from "../../ComponentesEstadisticas/NivelCompromiso";
import ResultadosRadar from "./ResultadosRadar";
import Widget from "../../../components/Widget/Widget";
import { tokenDatos } from '../../../conf/token';

const ResultadosAnteriores = ({handleClose, dataEncuAnte}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [punteoMP4, setPunteoMP4] = useState(0)
  const [loadMP4, setLoadMP4] = useState(true)
  const toggle = () => setIsOpen(!isOpen);
  const [encPunteo] = useMutation(encPunteoMP4)
  const [dataLogin] = useState(tokenDatos())

  const getPunteoMP4 = async () => {
    //let codEmp = localStorage.getItem('emp')
    //console.log(codJe)
    try{
      const {data} = await encPunteo(
        {
          variables:{
            input: { CODJE: String(dataLogin.CODEMP), ENC: dataEncuAnte.ENCNO}
          }
        }
      )
      //console.log('resp: ',JSON.parse(data.encChatTot.mensaje))
      
      let dataJson = JSON.parse(data.punteoMP4.mensaje)
      console.log('punteo mp4', dataJson.mp4)
      if(dataJson.mp4 === null){

      }else{
        setPunteoMP4(dataJson.mp4)
        //console.log('punteo mp4', dataJson.mp4)
        setLoadMP4(false)
      }
    }catch(ex){
      console.log(ex)
    }
   
    //console.log(dataTotalResp)
  }
  useEffect(() => {
    getPunteoMP4()
    console.log('Resultados anteriores', dataEncuAnte)
  }, [])
  return (
    <>
      <Row >
        <Col className="pr-grid-col mt-2" xs={12}  md={12} lg={12}  xl={12}>
          <Widget className="widget-p-md">
            <ResultadosRadar codigoEmp={String(dataEncuAnte.CODGE)} idEncuesta={dataEncuAnte.ENCNO} />
            <Stack spacing={2} alignItems="center">
            <>
                {
                  (loadMP4)?
                  ( 
                    <Skeleton className="mt-5"></Skeleton>
                  ):
                  (
                    <div>
                      <div className="headline-2 mb-3">Punteo MP4</div>
                        <div className={s.circle}>
                          {punteoMP4}
                        </div>
                    </div>
                  )
                }
              </> 
            </Stack>
          </Widget>
        </Col>
      </Row>
      <Row >
        <Col className="pr-grid-col mb-4 mt-2" xs={12}  md={12} lg={12}  xl={12}>
          <Widget className="widget-p-md">
            <NivelCompromiso codigoEmp={String(dataEncuAnte.CODGE)} idEncuesta={dataEncuAnte.ENCNO}></NivelCompromiso>
            <Button fullWidth={true} onClick={toggle} variant="outlined" color="baseDarkGray" startIcon={<QuizIcon />}  size="large">Pregunta abierta</Button>
          </Widget>
        </Col>
        <Col className="pr-grid-col mb-4 mt-2" xs={12}  md={12} lg={12}  xl={12}>
          {
            (isOpen === true) && (
              <Collapse isOpen={isOpen} >
                <PreguntaAbierta codigoEmp={String(dataEncuAnte.CODGE)} idEncuesta={dataEncuAnte.ENCNO} ></PreguntaAbierta>
              </Collapse>
            )
          }
          
        </Col>
      </Row>
  </>
   
  );
};

export default ResultadosAnteriores;
