import axios from 'axios'
import { API_SERVER } from './util/config'

class Api {
  ///static instance: Api = null;

  constructor(headers = {}) {
    this.base = axios.create({
      baseURL: API_SERVER,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    })
  }

  static getInstance() {
    if (!Api.instance) {
      Api.instance = new Api()
    }
    return Api.instance
  }

  static setInstance(token) {
    Api.instance = new Api({})
  }

  executeMutation = async (query, variables) => {
    try {
      const response = await this.base.post(
        '',
        JSON.stringify({
          query,
          variables,
        }),
      )
      return response.data.data
    } catch (e) {
      console.log(e)
    }
  }
}

export default Api
