import jwt from 'jwt-decode'

export function Idioma() {
try{
    //const dataUsuario = jwt(localStorage.getItem('idioma'))
    let idioma = localStorage.getItem('idioma')
    //console.log(localStorage.getItem('idioma'))
    //localStorage.setItem('token', data)
  
 //console.log(localStorage.getItem('nombre'))
    if(idioma === 'ES'){
        return Espanol;
    }else{
        return Ingles
    }
    
}catch(e){
    console.log(e)
    return [];
}

}

const Espanol = {
    idioma: 'ES',
    Inicio: {
        dato: 'INICIA HOY EL COMPROMISO DE TUS COLABORADORES CON ',
        quePre: '¿QUÉ ES',
        porPre: '¿POR QUÉ?',
        queRes: 'Empowerment es un programa de desarrollo de compromiso, que busca mejorar las métricas financieras y de productividad por medio de la gestión de personal de una forma única. Resolvemos el aspecto más importante para el éxito de los negocios; tener un equipo comprometido que se identifique con la institución a la que pertenece.',
        porRes: 'Con el programa de Empowerment trabajamos de la mano con los líderes y sus equipos para encontrar las oportunidades de mejora e implementar cambios en los puntos claves que deben ser tocados para lograr ese compromiso en los colaboradores.',
        mundo: 'EL MUNDO',
        actual: ' ACTUALMENTE',
        tiNoCompro: 'NO COMPROMETIDOS',
        tiCompro: 'COMPROMETIDOS',
        tiActNo: 'ACTIVAMENTE',
        tiActCompro: ' NO COMPROMETIDOS',
        NoCompro: 'Colaboradores que pueden ser productivos pero que no están conectados con la empresa. Es probable que se ausenten más días y es más probable que se vayan de la empresa. ',
        Compro: ' Colaboradores que se identifican con la empresa. Tienden a ser más productivos y a permanecer más tiempo dentro de la empresa. ',
        ActCompro: ' Colaboradores que están físicamente presentes, pero mentalmente ausentes. No están satisfechos con su situación laboral, tienden a contaminar a los demás colaboradores y buscan, activamente, perjudicar a la empresa.',
        tiEfecto: 'EFECTOS DEL',
        tiECompro: 'COMPROMISO',
        eUno: 'Menor Ausentismo',
        eDos: 'Menor Rotación (Organización de alta rotación)',
        eTres: 'Menor Rotación (Organización de baja rotación)',
        eCinco: 'Menos Robos',
        eSeis: 'Menores Accidentes Laborales',
        eCuatro: 'Menores Defectos de Calidad',
        eSiete: 'Mayor Productividad',
        eOcho: 'Mayores Ventas',
        eNueve: 'Mayor Utilidad',
        cPregunta: '¿CÓMO SE PUEDE TENER',
        cColaboradores: 'COLABORADORES COMPROMETIDOS?',
        textoColabo: 'El 70% de la variación del compromiso es determinado por el líder y su relación con el colaborador.',
        texto1Colabo: 'Es el encargado de velar que sus colaboradores sepan cuál es el trabajo que deben realizar.',
        texto2Colabo: 'Debe apoyarlos y defenderlos cuando sea necesario.',
        texto3Colabo: 'Explicar como su trabajo se conecta con los logros de la empresa.',
        comoFunciona: '¿CÓMO FUNCIONA',
        comoFuncionaEm: 'EMPOWERMENT',
        textoFunciona: 'Empowerment mide las necesidades intrínsecas y extrínsecas clave de los colaboradores para lograr un compromiso con la organización. Se utilizan herramientas de medición que, durante el proceso completo, sirven para valorizar condiciones humanas que afectan su productividad.',
        listo: '¿ESTÁS LISTO PARA TENER UN  ',
        trabajoCompro: 'EQUIPO DE TRABAJO COMPROMETIDO?',
        agenda: 'AGENDAR DEMO',
        contacto: 'Contáctanos',  
    },
    equipo: {
        nuestroEquipo: 'NUESTRO EQUIPO',
        puestoUno: 'CEO',
        nombreUno: 'CECILIA PÉREZ',
        descUno: 'Experiencia en servicio y atención al cliente, adquirida en The Walt Disney Company y experiencia en ventas.',
        descUnoOtro: 'Lic. en Marketing, emprendedora, Máster de INCAE',
        puestoDos: 'DIRECTOR',
        nombreDos: 'MANUEL PÉREZ',
        descDos: 'Experiencia gerenciando el área de recursos humanos con enfoque en el desarrollo humano.',
        descDosOtro: 'Psicólogo, emprendedor, MBA INCAE, Máster en Economía',
        PuestoTres: 'DIRECTOR',
        nombreTres: 'EDGARDO PÉREZ',
        descTres: '35 años de experiencia en empresas multinacionales, así como en la industria financiera, ocupando posiciones de alta gerencia y CEO.',
        descTresOtro: 'Ing. En Sistemas, MBA INCAE',
        info: 'PARA MAS INFORMACIÓN',
    },
    funacionalidad: {
        funcion: 'FUNCIONALIDADES',
        evaluacionTitulo: 'Evaluación',
        evaluDesc: 'Identifica cuales son las ',
        evaluDescS: 'necesidades intrínsecas y extrínsecas ',
        evaluDescOtro: 'de los miembros de los equipos de manera científica, la cual esta siendo constantemente validada.',
        evaluDesc2: 'Presenta las necesidades identificadas de una manera fácil de entender e ',
        evaluDescS2: 'indica puntualmente ',
        evaluDescS2Otro: 'sobre', 
        evaluDescOtro2: 'que debe trabajar el líder',
        evaluDescOtro2S: ' evaluado con su equipo.',
        reporteTitulo: 'Reportes',
        reporteDesc: 'Presenta ',
        reporteDescS: 'resultados en tiempo real ',
        reporteDescOtro: 'basados en las métricas establecidas para determinar las necesidades de los colaboradores.',
        reporteDesc2: 'Tablas identificando',
        reporteDescS2: ' que tan comprometidos o no ',
        reporteDescOtro2: 'están los colaboradores, ',
        reporteDescOtro2S: 'eNPS',
        reporteDescUltimo: ', cuantos usuarios del equipo han contestado y faltan por responder la evaluación.',
        reporteDesc3: 'Reportes comparativos ',
        reporteDescOtro3: 'por departamentos, asi como la evolución de cambios en las áreas trabajadas.',
        reporteDesc4: 'Gráfica de mapa de calor',
        reporteDescOtro4: ' para identificar por equipo las oportunidades de mejora y las áreas en estado óptimo.',
        planTitulo: 'Plan de Acción',
        planDesc: 'Se ',
        planDescS: 'diseñan y realizan planes de acción ',
        planDescOtro: 'que buscan mejorar los niveles de compromiso de los colaboradores.',
        planDesc2: 'Visibilidad de como ',
        planDesc2S: 'se desarrollan los planes de trabajo ',
        planDesc2Otro: ' de cada líder con los miembros de su equipo. ​',
        planDesc3: 'Funcionalidades ',
        planDesc3S: 'para el manejo y control de planes de acción.',
        segTitulo: 'Seguimiento',
        segDesc: 'Calendario con ',
        segDescS: 'indicadores para dar seguimiento',
        segDescOtro: ' a los planes de acción.',
        segnDesc2: 'El proceso de evaluaciones de Empowerment consta de',
        segDesc2S: 'ciclos continuos ',
        segDescU: 'que se realiza de 3 a 4 veces anuales, asegurando ',
        segDesc3: 'seguimiento y continuidad', 
        segDesc4: ' a la gestión humana.', 
    },
    contacto: {
        contacto: 'CONTÁCTANOS',
        desc: 'Será un gusto poder atenderle',
        telefono: 'Teléfono',
        formulario: 'Formulario de contacto',
        nombre: 'NOMBRE',
        apellidos: 'APELLIDOS',
        telefono: 'TELÉFONO',
        empresa: 'EMPRESA',
        mensaje: 'MENSAJE',
        boton: 'Enviar',
    },
    nav:{
        inicio: 'Inicio',
        quienes: 'Quiénes somos',
        funcio: 'Funcionalidades',
        contacto: 'Contáctanos',
        login: 'Inicio de sesión',
    }
}

const Ingles = {
    idioma: 'EN',
    Inicio: {
        dato: 'START MANAGING YOUR TEAM´S ENGAGEMENT WITH ',
        quePre: 'WHAT’S',
        porPre: 'WHY CHOOSE',
        queRes: 'Empowerment is an engagement development program that seeks to improve on productivity and financial metrics by managing people’s needs in a unique way.  We help you manage the most important key to a business success: having an engaged team that identifies with the organization',
        porRes: 'With empowerment, we work hand in hand with team leaders and their teams to find out exactly where we need to act to improve employee engagement.',
        mundo: 'THE WORLD ',
        actual: 'CURRENTLY STANDING',
        tiNoCompro: 'DISENGAGED',
        tiCompro: 'ENGAGED',
        tiActCompro: 'ACTIVELY DISENGAGED',
        tiActNo: 'ACTIVELY ',
        tiActCompro: 'DISENGAGED',
        NoCompro: 'Workers that can be productive but are not identified with the company or its goals.  They’re more likely to miss workdays, and are more likely to leave.',
        Compro: 'Workers that identify with the company.  They tend to be more productive, to stay longer, and to grow within the organization.',
        ActCompro: 'Employees that are physically present, but mentally absent.  They’re not happy about their current work situation and they tend to contaminate those around them.  They actively seek to hurt the company. ',
        tiEfecto: 'EFECTOS DEL',
        tiECompro: 'COMPROMISO',
        eUno: 'Fewer Absences',
        eDos: 'Less Rotation \n(High turnover organization)',
        eTres: 'Less Rotation (Low turnover organization)',
        eCuatro: 'Less Theft',
        eCinco: 'Minor In Safety Incidents',
        eSeis: 'Minor Quality Defects',
        eSiete: 'Higher Productivity',
        eOcho: 'Greater Sales',
        eNueve: 'Greater Utility',
        cPregunta: '¿HOW TO KEEP YOUR',
        cColaboradores: 'TEAMS ENGAGED?',
        textoColabo: 'The leader and their relationship to the team will dictate about 70% of the team’s engagement.',
        texto1Colabo: 'They’re in charge of making sure what the team’s role is and what work they need to do.',
        texto2Colabo: 'They’re in charge of supporting them, and admonishing them, when needed.',
        texto3Colabo: 'They’re responsible for transmitting the company goals and setting the team’s objectives',
        comoFunciona: 'HOW DOES',
        comoFuncionaEm: 'EMPOWERMENT WORK?',
        textoFunciona: 'Empowerment works by measuring employee’s intrinsic and extrinsic needs that lead to workplace engagement.  We use measuring tools that, along the entire process, provide insights as to what the team needs to improve productivity and results.',
        listo: '¿ARE YOU READY TO HAVE AN',
        trabajoCompro: 'ENGAGED TEAM?',
        agenda: 'SCHEDULE DEMO',
        contacto: 'Contact Us',
    } ,
    equipo: {
        nuestroEquipo: 'OUR TEAM',
        puestoUno: 'CEO',
        nombreUno: 'CECILIA PÉREZ',
        descUno: 'Experience in service and customer service, acquired at The Walt Disney Company and experience in sales.',
        descUnoOtro: 'Degree in Marketing, entrepreneur, Master from INCAE',
        puestoDos: 'DIRECTOR',
        nombreDos: 'MANUEL PÉREZ',
        descDos: 'Over 8 years of experience managing team needs, with a focus on creating incentive structures, increasing productivity and employee retention.',
        descDosOtro: 'Psychologist, entrepreneur, MBA INCAE, Master in Economics',
        puestoTres: 'DIRECTOR',
        nombreTres: 'EDGARDO PÉREZ',
        descTres: '35 years of experience in multinational companies, as well as in the financial industry, occupying senior management and CEO positions.',
        descTresOtro: 'Engineer in Systems, MBA INCAE',
        info: 'MORE INFORMATION',
    },
    funacionalidad: {
        funcion: 'FEATURES',
        evaluacionTitulo: 'Evaluation',
        evaluDesc: 'Identifies the ',
        evaluDescS: 'intrinsic and extrinsic needs ',
        evaluDescOtro: 'of team members in a scientific way, which is constantly being validated.',
        evaluDesc2: 'It presents the ',
        evaluDescS2: 'identified needs in an easy-to-understand way ',
        evaluDescS2Otro: 'and promptly ', 
        evaluDescOtro2: 'indicates what the evaluated leader should work on',
        evaluDescOtro2S: 'with the team.',
        reporteTitulo: 'Reporting',
        reporteDesc: 'It presents ',
        reporteDescS: 'results in real time',
        reporteDescOtro: ', based on the metrics established to determine the needs of collaborators.',
        reporteDesc2: 'Tables identifying ',
        reporteDescS2: 'how engaged or not',
        reporteDescOtro2: ' the collaborators are, ',
        reporteDescOtro2S: 'eNPS',
        reporteDescUltimo: ', how many users of the team have answered and still have to answer the evaluation.',
        reporteDesc3: 'Comparative reports',
        reporteDescOtro3: ' by departments, as well as the evolution of changes in the areas worked.',
        reporteDesc4: 'Heatmaps ',
        reporteDescOtro4: 'to identify by team the opportunities for improvement and the areas in optimal state.',
        planTitulo: 'Work Plans',
        planDesc: 'Work plans are designed and carried out ',
        planDescS: 'that seek to improve the levels of commitment of the team. ',
        planDescOtro: '',
        planDesc2: 'Visibility of',
        planDesc2S: 'how the work plans of each leader are developed ',
        planDesc2Otro: 'with the members of their team.​',
        planDesc3: 'Specific features ',
        planDesc3S: 'for the management and control of action plans.',
        segTitulo: 'Follow up',
        segDesc: 'Integrations with outlook and other calendars ',
        segDescS: ' to ensure that the work plans are always present.',
        segDescOtro: ' ',
        segnDesc2: 'Empowerment evaluation process consists of ',
        segDesc2S: 'continuous cycles ',
        segDescU: 'that are carried out 3 to 4 times a year, ',
        segDesc3: 'ensuring follow-up and continuity', 
        segDesc4: ' for the team. ',          
    },    
    contacto: {
        contacto: 'CONTACT US',
        desc: 'It will be a pleasure to hear from you',
        telefono: 'Phone',
        formulario: 'Contact Form',
        nombre: 'Name',
        apellidos: 'Last name',
        empresa: 'Company',
        mensaje: 'Message',
        boton: 'Send',
    },
    nav:{
        inicio: 'Start',
        quienes: 'About us',
        funcio: 'Features',
        contacto: 'Contact Us',
        login: 'Log in',
    }
}