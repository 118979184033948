import React from "react";
import {
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import s from "./ErrorPage.module.scss";

import errorImage from '../../assets/imagenParaQueFunciona.png'
import FooterIcon from "../../components/Icons/FooterIcon.js";

const ErrorPage = () => {
  return (
    <div className={s.pageContainer}>
      <div className={s.errorContainer}>
        <h1 className={s.errorCode}>404</h1>
        <p className={s.errorInfo}>
          Oops... Parece que la página que estás buscando ya no existe.
        </p>
        <p className={s.errorHelp}>
          
        </p>
        <Link to="/">
          <Button className={`${s.errorBtn} rounded-pill`} type="submit" color="secondary-red">
            Regresar
          </Button>
        </Link>
      </div>
      <div className={s.imageContainer}>
        <img className={s.errorImage} src={errorImage} alt="Error page" width="80" />
      </div>
      <div className={s.footer}>
        <span className={s.footerLabel}>2022 &copy; Soluciones TI</span>
        <FooterIcon />
      </div>
    </div>
  );
}

export default ErrorPage;
