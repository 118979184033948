import React, { useState } from "react";
import { 
  Form, 
  Input, 
  Button, 
  Col, 
  Row, 
  Select, 
  Alert, 
  message
} from "antd";
import Api from "../../../Api";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { 
  UPDATEEMP, 
  Departamento,
  Sucursal,
  Rol,
  Region,
  Puesto
} from "../../../conf/mutation";
import { tokenDatos } from '../../../conf/token';

const { Option } = Select;
const { TextArea } = Input;

const EditarEmpleado = ({ handleClose, idEmpleado }) => {
  const [form] = Form.useForm();
  const [msgAlert, setMsgAlert] = useState("Alerta de prueba");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [disableInput, setdisableInput] = useState(false);
  const [dataLogin, setDataLogin] = useState(tokenDatos())
  const [state, setState] = useState({});
  const [updateempleado] = useMutation(UPDATEEMP);
  const [ Dep ] = useMutation(Departamento);
  const [ Suc ] = useMutation(Sucursal);
  const [ DataRol ] = useMutation(Rol);
  const [ Re ] = useMutation(Region);
  const [ Pu ] = useMutation(Puesto);
  const [ dataDep, setDataDep] = useState([])
  const [dataSuc, setDataSuc] = useState([])
  const [datRol, setDatRol] = useState([])
  const [dataRe, setDataRe] = useState([])
  const [dataPu, setDataPu] = useState([])
  const [dataGenero, setDataGenero] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const cargarEmpleado = async () => {
    const post_query = `
        mutation {
            empresaGetEmpleado(input: {ID: ${idEmpleado}}) {
              ANTIGUEDAD
              CODGE
              CODEMP
              DEPARTAMENT
              EMAILEMP
              EMAILJEF
              EMPRESA
              ENCNO
              ID
              NOMBRE
              NOMGE
              PUESTO
              PUESTOGE
              REGION
              ROL
              SUCURSAL
              GENERO
            }
        }
        `;

    const post_values = {
      input: {
        ID: `${idEmpleado}`,
      },
    };
    //console.log(post_query)
    //console.log(post_values)
    const response = await Api.getInstance().executeMutation(
      post_query,
      post_values
    );
    let op = [];
    if (response.empresaGetEmpleado !== null) {
      console.log(response.empresaGetEmpleado )
      
      setState(response.empresaGetEmpleado);
      setLoading(false);
    }
  };
  const success = (value, tipo) => {
    messageApi.open({
      type: tipo,
      content: value,
    });
  };
  const onFinish = async (values) => {
    console.log(values)
    const dataEmp = JSON.stringify(values);
    const { data } = await updateempleado({
      variables: {
        input: { json: dataEmp },
      },
    });
    const { mensaje } = data.empresaUpdateemp;
    if (parseInt(mensaje) >= 1) {
      handleClose()
      Swal.fire({
        title: 'Información',
        text: 'Empleado modificado exitosamente',
        icon: 'success',
        confirmButtonColor: '#ff8f00',
        confirmButtonText: 'Ok'

    })
      
      //success("Datos actualizados correctamente", "success");
      
    } else {
      success("No se actualizo ninguna información", "warning");
    }
  };
  const validateMessages = {
    required: "${label} es un campo requerido",
  };
  const formItemLayout = {};

  const Cancelar = () => {
    handleClose()
  };
  const cargarRol = async ()=>{
    try{
        const { data } = await DataRol({
          variables: {
            input: { 
                EMP:  String(dataLogin.CODIGOEMPRESA),
                ENC:  String(dataLogin.ENCUESTANO),
            },
          },
        })
        let datoRol = JSON.parse(data.rol.mensaje)
        setDatRol(datoRol.respuesta)
      }catch(ex){
        console.log(ex)
      }   
  }
  const cargarSucursal = async ()=>{
    try{
        const { data } = await Suc({
          variables: {
            input: { 
                EMP:  String(dataLogin.CODIGOEMPRESA),
                ENC:  String(dataLogin.ENCUESTANO),
            },
          },
        })
        let dataSuc = JSON.parse(data.suc.mensaje)
        setDataSuc(dataSuc.respuesta)
      }catch(ex){
        console.log(ex)
      }   
  }
  const cargarRegion = async ()=>{
    try{
        const { data } = await Re({
          variables: {
            input: { 
                EMP:  String(dataLogin.CODIGOEMPRESA),
                ENC:  String(dataLogin.ENCUESTANO),
            },
          },
        })
        let dataReg = JSON.parse(data.region.mensaje)
        setDataRe(dataReg.respuesta)
      }catch(ex){
        console.log(ex)
      }   
  }
  const cargarPuesto = async ()=>{
    try{
        const { data } = await Pu({
          variables: {
            input: { 
                EMP:  String(dataLogin.CODIGOEMPRESA),
                ENC:  String(dataLogin.ENCUESTANO),
            },
          },
        })
        let dataPu = JSON.parse(data.puesto.mensaje)
        setDataPu(dataPu.respuesta)
      }catch(ex){
        console.log(ex)
      }   
  }
  const cargarDepartamento = async ()=>{
    try{
        const { data } = await Dep({
          variables: {
            input: { 
                EMP:  String(dataLogin.CODIGOEMPRESA),
                ENC:  String(dataLogin.ENCUESTANO),
            },
          },
        })
        let dataDep = JSON.parse(data.dep.mensaje)
        setDataDep(dataDep.respuesta)
      }catch(ex){
        console.log(ex)
      }   
  }
  const cargaGenero = async () => {
    setDataGenero([{
      GENERO: 'MARCULINO'
    },
    {
      GENERO: 'FEMENINO'
    }, ])
  }
  useEffect(() => {
    cargarEmpleado();
    //console.log(state);
    cargaGenero()
    cargarRol()
    cargarSucursal()
    cargarRegion()
    cargarPuesto()
    cargarDepartamento()
  }, []);
  return (
    <div className="ml-4 pl-4 mr-4 pr-4">
      <Form
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        layout="vertical"
        fields={[
          {
            name: ["ID"],
            value: state.ID,
          },
          {
            name: ["NOMBRE"],
            value: state.NOMBRE,
          },
          {
            name: ["EMAILEMP"],
            value: state.EMAILEMP,
          },
          {
            name: ["EMPRESA"],
            value: state.EMPRESA,
          },
          {
            name: ["CODEMP"],
            value: state.CODEMP,
          },
          {
            name: ["ANTIGUEDAD"],
            value: state.ANTIGUEDAD,
          },
          {
            name: ["PUESTO"],
            value: state.PUESTO,
          },
          {
            name: ["ROL"],
            value: state.ROL,
          },
          {
            name: ["REGION"],
            value: state.REGION,
          },
          {
            name: ["DEPARTAMENT"],
            value: state.DEPARTAMENT,
          },
          {
            name: ["SUCURSAL"],
            value: state.SUCURSAL,
          },
          {
            name: ["CODGE"],
            value: state.CODGE,
          },
          {
            name: ["NOMGE"],
            value: state.NOMGE,
          },
          {
            name: ["EMAILJEF"],
            value: state.EMAILJEF,
          },
          {
            name: ["PUESTOGE"],
            value: state.PUESTOGE,
          },
          {
            name: ["ENCNO"],
            value: state.ENCNO,
          },
          {
            name: ["GENERO"],
            value: state.GENERO,
          },
        ]}
      >
        <Row gutter={[16, 8]}>
          {contextHolder}
          <Col xs={24} md={24} lg={24} xl={24}>
            <Form.Item
              {...formItemLayout}
              name={["ID"]}
              label="ID"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["NOMBRE"]}
              label="Nombre"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["EMAILEMP"]}
              label="Email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["CODEMP"]}
              label="Código"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["ANTIGUEDAD"]}
              label="Antiguedad"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["PUESTO"]}
              label="Puesto"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                 style={{ width: '100%' }}
              >
                  {dataPu != null? dataPu.map((data, index) => <Option value={data.PUESTO} key={`${data.PUESTO}${index}`}>{data.PUESTO}</Option>):(<Option></Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["ROL"]}
              label="Rol"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                 style={{ width: '100%' }}
              >
                  {datRol != null? datRol.map((data, index) => <Option value={data.ROL} key={`${data.ROL}${index}`}>{data.ROL}</Option>):(<Option></Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["REGION"]}
              label="Region"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                 style={{ width: '100%' }}
              >
                  {dataRe != null? dataRe.map((data, index) => <Option value={data.REGION} key={`${data.REGION}${index}`}>{data.REGION}</Option>):(<Option></Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["DEPARTAMENT"]}
              label="Departamento"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                 style={{ width: '100%' }}
              >
                  {dataDep != null? dataDep.map((data, index) => <Option value={data.DEPARTAMENT} key={`${data.DEPARTAMENT}${index}`}>{data.DEPARTAMENT}</Option>):(<Option></Option>)}
              </Select>
        
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["SUCURSAL"]}
              label="Sucursal"
              rules={[
                {
                  required: true,
                },
              ]}
            >
               <Select
                 style={{ width: '100%' }}
              >
                  {dataSuc != null? dataSuc.map((data, index) => <Option value={data.SUCURSAL} key={`${data.SUCURSAL}${index}`}>{data.SUCURSAL}</Option>):(<Option></Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["GENERO"]}
              label="Género"
              rules={[
                {
                  required: true,
                },
              ]}
            >
               <Select
                 style={{ width: '100%' }}
                 
              >
                {dataGenero != null? dataGenero.map((data, index) => <Option value={data.GENERO} key={`${data.GENERO}${index}`}>{data.GENERO}</Option>):(<Option></Option>)}
                  
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["NOMGE"]}
              label="Nombre jefe"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["EMAILJEF"]}
              label="Email jefe"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["CODGE"]}
              label="Código jefe"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["PUESTOGE"]}
              label="Puesto Jefe"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item
              {...formItemLayout}
              name={["ENCNO"]}
              label="Encuesta no"
              value={1}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24} xl={24}>
            {showAlert === true ? (
              <Alert
                message="Alerta"
                description={msgAlert}
                type="warning"
                showIcon
                closable
              />
            ) : (
              <div></div>
            )}
          </Col>

          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Modificar
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12}>
            <Form.Item>
              <Button
                type="default"
                loading={loading}
                onClick={(event) => Cancelar()}
                block
              >
                Cancelar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditarEmpleado;
