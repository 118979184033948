import React, {useEffect, useState} from "react";
import {
    Progress,
} from "reactstrap";
import{
  Skeleton,
  Tooltip
  } from 'antd';
  import { encNiveles } from '../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import s from "./ComponentesEstadisticas.module.scss";
import Widget from "../../components/Widget/Widget.js";


const NivelCompromiso = ({codigoEmp, idEncuesta}) => {
  const [EncuestaNiveles] = useMutation(encNiveles)
  const [load, setLoad] = useState(true)
  const [dataNiveles, setdataNiveles] = useState([]);
  
  const getTotalEnc = async () => {
   
    //console.log(codigoEmp)
    try{
      const {data} = await EncuestaNiveles(
        {
          variables:{
            input: { CODJE: codigoEmp, ENC: idEncuesta }
          }
        }
      )
      console.log('resp niveles: ',JSON.parse(data.nivelcom.mensaje))
      let dataJson = JSON.parse(data.nivelcom.mensaje)
      setdataNiveles(dataJson.NivelCompromiso)
      /*let dataNi = {
        Comprometidos: 20,
        Nocomprometidos: 30,
        Activamentenocomprometidos: 50
      }
      setdataNiveles(dataNi)*/
      if(dataJson.NivelCompromiso.Activamentenocomprometidos === 'NaN'){
       
       
      }
      else{
        setLoad(false)
      }
      
  
      //console.log(dataTotalResp)
    }catch(ex){
      console.log(ex)
    }
    
  }
  useEffect(() => {
    getTotalEnc()
  }, [])
  return (
    <>
      {
        (load )?
        ( 
          <Skeleton className="mt-5"></Skeleton>
        ):
        (
          <>
            <div className="headline-2">Nivel de compromiso</div>
          
            <div className={s.goals}>
            <div className="d-flex flex-column">
                <div className={s.activity}>
                  <p className="body-2">Comprometidos</p>
                </div>
                <Tooltip title={`Comprometidos ${dataNiveles.Comprometidos}%`} color='#6E7687'>
                  <Progress color="secondary-yellow" striped className="progress-md" value={dataNiveles.Comprometidos} >{`${dataNiveles.Comprometidos}%`}</Progress>
                </Tooltip>
            </div>
            <div className="d-flex flex-column mt-3">
                <div className={s.activity}>
                  <p className="body-2">No comprometidos</p>
                </div>
                <Tooltip title={`No comprometidos ${dataNiveles.Nocomprometidos}%`} color='#6E7687'>
                  <Progress color="dark-gray" striped className="progress-md" value={dataNiveles.Nocomprometidos} >{`${dataNiveles.Nocomprometidos}%`}</Progress>
                </Tooltip>
            </div>
            <div className="d-flex flex-column mt-3">
                <div className={s.activity}>
                  <p className="body-2">Activamente no comprometidos</p>
                </div>
                <Tooltip title={`Activamente no comprometidos ${dataNiveles.Activamentenocomprometidos}%`} color='#6E7687'>
                  <Progress color="gray-900" striped className="progress-md" value={dataNiveles.Activamentenocomprometidos}>{`${dataNiveles.Activamentenocomprometidos}%`}</Progress>
                </Tooltip>
                
            </div>
            </div>
          </>  
        )
      }
    </>
  );
};

export default NivelCompromiso;
