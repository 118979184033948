import jwt from 'jwt-decode'

export function tokenDatos() {
try{
    const dataUsuario = jwt(localStorage.getItem('token'))

    //console.log(dataUsuario)
    //localStorage.setItem('token', data)
  
 //console.log(localStorage.getItem('nombre'))
  
    return dataUsuario;
}catch(e){
    console.log(e)
    return [];
}

}