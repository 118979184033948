import React, { useEffect, useState, useRef } from "react";
import{
Row,
Col,
Table,
Card,
Button,
Skeleton,
Tag,
Avatar,
Space,
Input
} from 'antd';
import 'antd/dist/reset.css';
import {
  SearchOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import Api from '../../../Api';
import { gql, useQuery , useMutation} from '@apollo/client';
import { tokenDatos } from '../../../conf/token';
import { ReportePlanAcc } from '../../../conf/mutation';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import 'react-pivottable/pivottable.css';
import Widget from "../../../components/Widget/Widget.js";
import PlanAccionDonut from "./PlanAccionDonut";
import Highlighter from 'react-highlight-words';
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import  "./estilotabla.css"



const ReportePlanAccion = () =>{

    const [RptPlanAccion] = useMutation(ReportePlanAcc)
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(true)
    const [dataLogin, setDataLogin] = useState(tokenDatos())
    const [dataReporte, setDataReporte] = useState([])
    const [searchText, setSearchText] = useState('');
    const [editingKey, setEditingKey] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [nombreAnterior, setNombreAnterior] = useState('')
    
    const [state, setState] = useState([]);

 
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Ingrese texto para buscar`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Borrar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              
              Cerrar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const cancel = () => {
      setEditingKey('');
    };

    let sameKey;
    /*
    const columns = [
      {
        title: "Nombre",
        dataIndex: "NOMBREJEFE",
        key: "NOMBREJEFE",
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Estatus plan de acción',
        dataIndex: 'ESTADO',
        key: 'ESTADO', 
        render: (items) => {
          return items.map((item, index) => (
            <div className="" key={index}>
              {item.ESTADO}
            </div>
          ));
        },
        ...getColumnSearchProps('ESTADO'),
      },
      {
        title: 'Descripción',
        dataIndex: 'TIPO',
        key: 'TIPO', 
        render: (items) => {
          return items.map((item, index) => <div key={index}>{item.TIPO}</div>);
        },
        ...getColumnSearchProps('TIPO'),
      },
      {
        title: 'Días para finalizar',
        dataIndex: 'DIASVENCIDOS',
        key: 'DIASVENCIDOS', 
        render: (items) => {
          return items.map((item, index) => (
            <div key={index}>{item.DIASVENCIDOS}</div>
          ));
      },
        ...getColumnSearchProps('DIASVENCIDOS'),
    },
      {
          title: 'Status',
          dataIndex: 'plan',
          key: 'plan',  
          align: 'center',
          
          render: (items) => {
            return items.map((item, index) => (
              <>
                {Estado(item.plan)}
              </>
            
            ));
          },
      },
  ];*/
  
    const exportarExcel = () => {
        const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Reporte');
      worksheet.columns = [
          { header: 'Nombre', key: 'NOMBREJEFE', width: 40 },
          { header: 'Estado', key: 'ESTADO', width: 25 },
          { header: 'Tipo', key: 'TIPO', width: 25 },
          { header: 'Dias_vencidos', key: 'DIASVENCIDOS', width: 35 },
          { header: 'Plan_accion', key: 'PLANACCION', width: 35 },
      ];
      
      dataReporte.map((item, index2) => (
        worksheet.addRow(item)
       
      ))
      workbook.xlsx.writeBuffer().then(function (data) {
        var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        saveAs(blob, 'reporte.xlsx');
      })
    }
    const cargarData = async ()=>{
        try{
            const { data } = await RptPlanAccion({
              variables: {
                input: { 
                    EMP:  parseInt(dataLogin.CODIGOEMPRESA),
                    ENC: parseInt(dataLogin.ENCUESTANO)
                },
              },
            })
          
            //console.log('resp RPT: ',JSON.parse(data.empplan.mensaje))
            let dataJson = JSON.parse(data.empplan.mensaje)
          
            //setDataReporte(dataJson.respuesta)
            let dataPru = []

            dataJson.respuesta.map((item, index2) => (
              dataPru.push({
                NOMBREJEFE: (item.NOMBREJEFE === null)?(''):(item.NOMBREJEFE),
                ESTADO: (item.ESTADO === null)?(''):(item.ESTADO),
                TIPO: (item.TIPO === null)?(''):(item.TIPO),
                DIASVENCIDOS: (item.DIASVENCIDOS === null)?(''):(item.DIASVENCIDOS),
                PLANACCION: (item.PLANACCION === null)?(''):(item.PLANACCION)
              })
             
            ))
            //console.log(dataPru)
            setDataReporte(dataPru)
            //console.log(dataPru)
            setLoading(false)
            setLoadingData(false)
            //console.log(dataJson.respuesta)
            //console.log(dataTotalResp)
          }catch(ex){
            console.log(ex)
          }
          
    }
    const Estado = (record) => {
      //console.log(record.DIASVENCIDOS)
      if(record.PLANACCION === '0'){
        return (
          <Avatar 
          size="large" 
          style={{
            backgroundColor: '#87d068',
            color: '#87d068',
          }}
        />
        )
      }      
      else if(record.PLANACCION === '2' ){
          return (
            <Avatar 
            size="large" 
            style={{
              backgroundColor: '#ff4d4f',
              color: '#ff4d4f',
            }}
          />
          )
      }
      else if(record.PLANACCION === '1'){
        return (
          <Avatar 
          size="large" 
          style={{
            backgroundColor: '#faad14',
            color: '#faad14',
          }}
        />
        )
      }
          
      }

    useEffect(() => {
        cargarData();
       //console.log(newData)
    }, [])
    const uniqueNames = Array.from(
      new Set(dataReporte.map((item) => item.NOMBREJEFE))
    );

    const newData = uniqueNames.map((name) => {
      const items = dataReporte.filter((item) => item.NOMBREJEFE === name);
      return {
        NOMBREJEFE: name,
        items,
      };
    });

    const columnss = [
     {
       title: "Nombre Jefe",
       dataIndex: "NOMBREJEFE",
       key: "NOMBREJEFE",
       render: (text) => <span>{text}</span>,
       ...getColumnSearchProps('NOMBREJEFE'),
     },
     {
       title: "Estado",
       dataIndex: "items",
       key: "ESTADO",
       render: (items) => {
         return items.map((item, index) => (
           <div  className="ta" key={index}>
             {item.ESTADO}
           </div>
         ));
       },
     },
     {
       title: "Tipo",
       dataIndex: "items",
       key: "TIPO",
       render: (items) => {
         return items.map((item, index) => <div className="ta" key={index}>{item.TIPO}</div>);
       },
     },
     {
       title: "Días Vencidos",
       dataIndex: "items",
       key: "DIASVENCIDOS",
       render: (items) => {
         return items.map((item, index) => (
           <div className="ta" key={index}>{item.DIASVENCIDOS}</div>
         ));
       },
     },
    
     {
       title: "Plan Acción",
       dataIndex: "items",
       key: "PLANACCION",
       render: (items) => {
         return items.map((item, index) => (
          
          <>
          {Estado(item)}
          <div className="mt-1"></div>
          
        </>
         ));
       },
     },

   ];
    return(
        <>
            <div className="headline-2 mb-3">Reporte plan de acción</div>
            {
            
            (loading )?
            (<Skeleton></Skeleton>):
            (
              <>
                <Row>
                   
                    <Col span={24}>
                        <Card>
                            <Row>
                                <Col span={18}></Col>

                                <Col span={6}>
                                    
                                    <Button
                                        icon={<FileExcelOutlined />}
                                        type="primary"
                                        onClick={exportarExcel}
                                        block
                                    >
                                        Exporta a excel
                                    </Button>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                            <Col span={24}>
                                <Table 
                                    loading={loadingData} 
                                    columns={columnss} 
                                    dataSource={newData} 
                                    
                                    bordered
                                    pagination={{
                                        pageSize: 10,
                                    }}
                                />
                            </Col>
                            </Row>
                    </Card>
                </Col>
             
              </Row>
              <Row>
                  <Col className="mt-3"> 
                  <Widget className="widget-p-md">
                    <div className="headline-2 mb-3">Gerentes con plan de acción</div>
                      <PlanAccionDonut idEmpresa = {String(dataLogin.CODIGOEMPRESA)} idEncuesta = {dataLogin.ENCUESTANO} codEmp = {String(dataLogin.CODEMP)}></PlanAccionDonut>
                  </Widget>
                </Col>
              </Row>
              </>
            )
        }
      </>
    );
}
export default ReportePlanAccion;