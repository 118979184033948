import React, { useState } from "react";

import NavBarindex from "./componet/NavBarindex";
import ProgressBar from "react-customizable-progressbar";
import "./estilo.css";

import uno from "./img/uno.svg";
import dos from "./img/dos.svg";
import tres from "./img/tres.svg";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import { Idioma } from "../../conf/Idioma";
import menorAusentismo from "./img/MenorAusentismoIcono.png";
import menorRotacion from "./img/MenorRotacionIcono.png";
import menosRobo from "./img/MenorRobosIcono.png";
import menosAccidente from "./img/MenoresAccidenteIcono.png";
import mayorProductividad from "./img/MayorProductividadIcono.png";
import mayorVenta from "./img/MayoresVentasIcono.png";
import mayorUtilidad from "./img/MayorUtilidadIcono.png";
import menorDefecto from "./img/MenorDefectosIcono.png";
import { useHistory, useLocation } from "react-router-dom";

const Index = () => {
  let history = useHistory();
  // const [valor, setvalor] = useState(50);
  const [datosIdioma] = useState(Idioma());
  const contacto = () => {
    history.push("/contacto");
  };
  return (
    <>
      <NavBarindex
        pageindex="active"
        pagesomos="nav-link"
        pagefuncional="nav-link"
        pagecontacto="nav-link"
      />
      <div className="container-fluid h-100">
        <Element name={"ingreso"}>
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center  divtam animate_animated animatefadeInLeft animate_delay-1s">
              <h1 className="text-wrap mt-5 text-lg text-center text-sm m-2 text-justify">
                {datosIdioma.Inicio.dato} <br></br>{" "}
                <p style={{ color: "#F58F50" }}>EMPOWERMENT</p>
              </h1>

              <h1></h1>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center divFondo2 divtam animate_animated animatefadeInRight animate_delay-2s"></div>
          </div>
        </Element>
        <div className="row bg-white">
          <div className="col-md-12">
            <Element name={"ingreso2"}>
              <div className="row bkim">
                <div className="col-lg-6 d-flex align-items-center justify-content-center  divtam animate_animated animatefadeInLeft animate_delay-2s fondotra">
                  <div className="row mt-5">
                    <div className="col-md-12 ">
                      <br />
                      <br />
                      <br />
                      <br />
                      <h1 className="text-center text-wrap mt-5 ">
                        {datosIdioma.Inicio.quePre} <br></br>{" "}
                        <p style={{ color: "#F58F50" }}>EMPOWERMENT?</p>
                      </h1>
                    </div>

                    <div className="col-md-12 lign-items-center justify-content-center margen ">
                      <p className="text-justify">
                        {datosIdioma.Inicio.queRes}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-center bcoloremp  divtam animate_animated animatefadeInRight animate_delay-3s fondotra">
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <br />
                      <br />
                      <br />
                      <br />
                      <h1 className="text-center text-wrap">
                        <p style={{ color: "#F58F50", margin: "0" }}>
                          {datosIdioma.Inicio.porPre}
                        </p>
                        <p>EMPOWERMENT?</p>
                      </h1>
                    </div>
                    <div className="col-md-12 lign-items-center justify-content-center margen ">
                      <p className="text-justify">
                        {datosIdioma.Inicio.porRes}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Element>
          </div>
        </div>

        <div className="col-md-24  fonmuactual">
          <div className="row mundomar bg-white">
            <div className="col-md-12 mb-5">
              <div className="col-md-12 animate_animated animatefadeInLeft animate_delay-1s mb-5">
                <div className="col-md-12 mundoactualmargin">
                  <h1 className="text-center text-wrap mt-5">
                    {datosIdioma.Inicio.mundo}
                    <p style={{ color: "#F58F50", display: "inline" }}>
                      {datosIdioma.Inicio.actual}
                    </p>
                  </h1>
                </div>
              </div>

              <div className="row animate_animated animatefadeInRight animate_delay-2s ">
                <div className="col-lg-4 justify-content-center align-items-center">
                  <div className="col-md-12  d-flex justify-content-center align-items-center">
                    <ProgressBar
                      radius={100}
                      progress="59"
                      strokeWidth={20}
                      strokeColor="#ff190a"
                      strokeLinecap="round"
                      trackStrokeWidth={20}
                      initialAnimation={true}
                      transition="1.5s ease 0.5s"
                      trackTransition="0s ease"
                    >
                      <div className="indicator tamundoactual ">
                        <div>
                          <strong>59%</strong>
                        </div>
                      </div>
                    </ProgressBar>
                  </div>
                  <div className="col-md-12">
                    <h4 className="  text-center text-wrap">
                      <p style={{ color: "#F58F50" }}>
                        {datosIdioma.Inicio.tiNoCompro}
                      </p>
                    </h4>
                    <p className="  text-justify">
                      {datosIdioma.Inicio.NoCompro}
                      <a href="https://talentsnapshot.com/wp-content/uploads/downloads/2017/04/Impact_of_Employee_Engagement_on_Performance.pdf">
                        (HBS 2013)
                      </a>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <div className="col-md-12  d-flex justify-content-center align-items-center">
                    <ProgressBar
                      radius={100}
                      progress="30"
                      strokeWidth={20}
                      strokeColor="#ff190a"
                      strokeLinecap="round"
                      trackStrokeWidth={20}
                      initialAnimation={true}
                      transition="1.5s ease 0.5s"
                      trackTransition="0s ease"
                    >
                      <div className="indicator tamundoactual ">
                        <div>
                          <strong>30%</strong>
                        </div>
                      </div>
                    </ProgressBar>
                  </div>
                  <div className="col-md-12">
                    <h4 className="text-center text-wrap">
                      <p style={{ color: "#F58F50" }}>
                        {datosIdioma.Inicio.tiCompro}
                      </p>{" "}
                    </h4>
                    <p className="text-justify">
                      {datosIdioma.Inicio.Compro}
                      <a href="https://talentsnapshot.com/wp-content/uploads/downloads/2017/04/Impact_of_Employee_Engagement_on_Performance.pdf">
                        (HBS 2013)
                      </a>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <div className="col-md-12  d-flex justify-content-center align-items-center">
                    <ProgressBar
                      radius={100}
                      progress="11"
                      strokeWidth={20}
                      strokeColor="#ff190a"
                      strokeLinecap="round"
                      trackStrokeWidth={20}
                      initialAnimation={true}
                      transition="1.5s ease 0.5s"
                      trackTransition="0s ease"
                    >
                      <div className="indicator tamundoactual ">
                        <div>
                          <strong>11%</strong>
                        </div>
                      </div>
                    </ProgressBar>
                  </div>
                  <div className="col-md-12">
                    <h4 className="text-center text-wrap">
                      <p style={{ color: "#F58F50" }}>
                        {datosIdioma.Inicio.tiActNo} <br />
                        {datosIdioma.Inicio.tiActCompro}
                      </p>
                    </h4>
                    <p className="text-justify">
                      {datosIdioma.Inicio.ActCompro}
                      <a href="https://talentsnapshot.com/wp-content/uploads/downloads/2017/04/Impact_of_Employee_Engagement_on_Performance.pdf">
                        (HBS 2013)
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-24 efectoform ">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row ">
                  <div className="col-md-12 mundoactualmargin mt-5">
                    <h1 className="text-center text-wrap mt-5">
                      {datosIdioma.Inicio.tiEfecto}
                      <p style={{ color: "#F58F50", display: "inline" }}>
                        {datosIdioma.Inicio.tiECompro}
                      </p>
                    </h1>
                  </div>

                  <div className="col-md-12 ">
                    <div className="row ">
                      <div className="col-md-1"></div>
                      <div className="col-lg-3 mb-3">
                          <FeatureCard
                            porcent={97}
                            title={datosIdioma.Inicio.eUno}
                            img={menorAusentismo}
                          />
                      </div>

                      <div className="col-lg-3 mb-3">
                          <FeatureCard
                            porcent={24}
                            title={datosIdioma.Inicio.eDos}
                            img={menorRotacion}
                          />
                      </div>
                      <div className="col-lg-3 mb-3">
                          <FeatureCard
                            porcent={59}
                            title={datosIdioma.Inicio.eTres}
                            img={menorRotacion}
                          />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mar">
                    <div className="row ">
                      <div className="col-md-1"></div>
                      <div className="col-lg-3 mb-3">
                         <FeatureCard
                            porcent={28}
                            title={datosIdioma.Inicio.eCinco}
                            img={menosRobo}
                          />
                      </div>
                      <div className="col-lg-3 mb-3">
                         <FeatureCard
                            porcent={70}
                            title={datosIdioma.Inicio.eSeis}
                            img={menosAccidente}
                          />
                      </div>
                      <div className="col-lg-3 mb-3">
                         <FeatureCard
                            porcent={40}
                            title={datosIdioma.Inicio.eCuatro}
                            img={menorDefecto}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mar">
                    <div className="row ">
                      <div className="col-md-1"></div>
                      <div className="col-lg-3 mb-3">
                      <FeatureCard
                            porcent={17}
                            title={datosIdioma.Inicio.eSiete}
                            img={mayorProductividad}
                          />
                      </div>
                      <div className="col-lg-3 mb-3">
                      <FeatureCard
                            porcent={20}
                            title={datosIdioma.Inicio.eOcho}
                            img={mayorVenta}
                          />
                      </div>
                      <div className="col-lg-3 mb-3">
                      <FeatureCard
                            porcent={21}
                            title={datosIdioma.Inicio.eNueve}
                            img={mayorUtilidad}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-5">
                    <h4 className="text-center text-wrap">
                      <a href="https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx">
                        Gallup 2015
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row bkim">
          <div className="col-md-12 mundoactualmargin mt-5">
            <h1 className="text-center text-wrap mt-5">
              {datosIdioma.Inicio.cPregunta}
              <br />
              <p style={{ color: "#F58F50" }}>
                {datosIdioma.Inicio.cColaboradores}
              </p>{" "}
            </h1>
            <p className="text-center text-wrap">
              {datosIdioma.Inicio.textoColabo}
            </p>
          </div>
          <div className="row bkim">
            <div className="col-lg-4  text-center">
              <div class="divsombra div mx-auto">
                <h1 className="colabh1">01</h1>
                <img
                  className="imgcol"
                  src={uno}
                  alt="Encargado"
                  width="300"
                  height="400"
                ></img>
                <p className="pcomp text-justify">
                  {datosIdioma.Inicio.texto1Colabo}
                </p>
              </div>
            </div>

            <div className="col-lg-4 text-center">
              <div class="divsombra div mx-auto">
                <h1 className="colabh1">02</h1>
                <img
                  className="imgcol"
                  src={dos}
                  alt="Encargado"
                  width="300"
                  height="400"
                ></img>
                <p className="pcomp text-justify">
                  {datosIdioma.Inicio.texto2Colabo}
                </p>
              </div>
            </div>

            <div className="col-lg-3 text-center">
              <div class="divsombra div mx-auto">
                <h1 className="colabh1">03</h1>
                <img
                  className="imgcol"
                  src={tres}
                  alt="Encargado"
                  width="300"
                  height="400"
                ></img>
                <p className="pcomp text-justify">
                  {datosIdioma.Inicio.texto3Colabo}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-24  comofunciona mb-5 ">
          <div className="row comofuncionader">
            <div className="col-md-12 mt-5">
              <div className="row">
                <div className="col-md-2"></div>
                <div class="col-lg-4 text-right ">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://static.wixstatic.com/media/b91e83_5f71d733dd624aa7a2ac424a4f0bc847~mv2.png/v1/crop/x_0,y_98,w_570,h_452/fill/w_455,h_363,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b91e83_5f71d733dd624aa7a2ac424a4f0bc847~mv2.png"
                      alt="Italian Trulli"
                    />
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <h1 className="text-center text-wrap mt-5">
                    {datosIdioma.Inicio.comoFunciona}
                    <br />
                    <p style={{ color: "#F58F50" }}>
                      {datosIdioma.Inicio.comoFuncionaEm}
                    </p>{" "}
                  </h1>
                  <p className="ml-5 mr-5 text-justify">
                    {datosIdioma.Inicio.textoFunciona}
                  </p>
                </div>

                <div className="col-md-12 estalistoma">
                  <h1 className="text-center text-wrap">
                    {datosIdioma.Inicio.listo} &nbsp;
                    <p style={{ color: "#F58F50" }}>
                      {datosIdioma.Inicio.trabajoCompro}
                    </p>{" "}
                  </h1>
                </div>
                <div className="col-md-12 text-center text-wrap estlismabu">
                  <button className="myButton " onClick={() => contacto()}>
                    {datosIdioma.Inicio.agenda}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer mt-auto py-3 bg-light">
          <div className="container text-left">
            <span className="text-muted">
              {datosIdioma.Inicio.contacto} <br />​ 502 - 4770 - 9215 <br />​
              info@empowerment.com
            </span>
          </div>
        </footer>
        {/* <div className="row h-100 colblanco">
          
         
  
    
        </div> */}

        {/* <div className="col-md-24 ">
         
          <div className="row bkim ">
            <div className="col-md-24 mar2 bkim">
              <div className="row bkim ">
                <div className="col-md-12  ">
                  <div className="row ml-5 ">
                    <div className="col-md-12 mundoactualmargin mt-5">
                      <h1 className="text-center text-wrap mt-5">
                        {datosIdioma.Inicio.cPregunta}
                        <br />
                        <p style={{ color: "#F58F50" }}>
                          {datosIdioma.Inicio.cColaboradores}
                        </p>{" "}
                      </h1>
                      <p className="text-center text-wrap">
                        {datosIdioma.Inicio.textoColabo}
                      </p>
                    </div>

                    <div className="col-md-24 align-items-center">
                      <div className="row justify-content-center">
                        <div className="col-md-1  "></div>
                        <div className="col-md-3  ">
                          <div class="divsombra div ">
                            <h1 className="colabh1">01</h1>
                            <img
                              className="imgcol"
                              src={uno}
                              alt="Encargado"
                              width="300"
                              height="400"
                            ></img>
                            <p className="pcomp text-justify">
                              {datosIdioma.Inicio.texto1Colabo}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div class="divsombra div ">
                            <h1 className="colabh1">02</h1>
                            <img
                              className="imgcol"
                              src={dos}
                              alt="Encargado"
                              width="300"
                              height="400"
                            ></img>
                            <p className="pcomp text-justify">
                              {datosIdioma.Inicio.texto2Colabo}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div class="divsombra div ">
                            <h1 className="colabh1">03</h1>
                            <img
                              className="imgcol"
                              src={tres}
                              alt="Encargado"
                              width="300"
                              height="400"
                            ></img>
                            <p className="pcomp text-justify">
                              {datosIdioma.Inicio.texto3Colabo}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
          <div className="col-md-24  comofunciona mb-5 ">
            <div className="row comofuncionader">
              <div className="col-md-12 mt-5">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div class="col-md-4 text-right ">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://static.wixstatic.com/media/b91e83_5f71d733dd624aa7a2ac424a4f0bc847~mv2.png/v1/crop/x_0,y_98,w_570,h_452/fill/w_455,h_363,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b91e83_5f71d733dd624aa7a2ac424a4f0bc847~mv2.png"
                        alt="Italian Trulli"
                      />
                    </div>
                  </div>

                  <div className="col-md-4 ">
                    <h1 className="text-center text-wrap mt-5">
                      {datosIdioma.Inicio.comoFunciona}
                      <br />
                      <p style={{ color: "#F58F50" }}>
                        {datosIdioma.Inicio.comoFuncionaEm}
                      </p>{" "}
                    </h1>
                    <p className="ml-5 mr-5 text-justify">
                      {datosIdioma.Inicio.textoFunciona}
                    </p>
                  </div>

                  <div className="col-md-12 estalistoma">
                    <h1 className="text-center text-wrap">
                      {datosIdioma.Inicio.listo} &nbsp;
                      <p style={{ color: "#F58F50" }}>
                        {datosIdioma.Inicio.trabajoCompro}
                      </p>{" "}
                    </h1>
                  </div>
                  <div className="col-md-12 text-center text-wrap estlismabu">
                    <button className="myButton " onClick={() => contacto()}>
                      {datosIdioma.Inicio.agenda}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer mt-auto py-3 bg-light">
            <div className="container text-left">
              <span className="text-muted">
                {datosIdioma.Inicio.contacto} <br />​ 502 - 4770 - 9215 <br />​
                info@empowerment.com
              </span>
            </div>
          </footer>
        </div> */}
      </div>
    </>
  );
};

export default Index;

const FeatureCard = ({ porcent, title, description, img }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card service-wrapper rounded border-0 shadow">
          <div className="icon text-center text-custom h3  rounded bg-white color">
            <span
              className="uim-svg"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <p>{porcent}%</p>
            </span>
          </div>
          <div className="content mt-4">
            <div className="row  ms-4">
              <h5 className="title" style={{ height: "150px" }}>
                {title}
              </h5>
              <div className="mt-3"></div>
            </div>
          </div>
          <div className="big-icon h1 text-custom">
            <span
              className="uim-svg"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <img
                className="imgul img-fluid"
                src={img}
                width="100px"
                height="100px"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
