import React, { useState } from "react";
import NavBarindex from "./componet/NavBarindex";
import "./estilo.css";
import { Idioma } from "../../conf/Idioma";
import { useHistory, useLocation} from 'react-router-dom';

const Eqipo = () => {
  let history = useHistory()
  const [datosIdioma] = useState(Idioma())
  const contacto = () =>{
    history.push('/contacto')
  }
  return (
    <>
      <NavBarindex
        pageindex="nav-link"
        pagesomos="active"
        pagefuncional="nav-link"
        pagecontacto="nav-link"
      />
      <div className="container-fluid colblanco">
        <div className="col-md-12 ">
          <div className="row ">
            <div className="col-lg-12 d-flex align-items-center justify-content-center marequipo">
              <h1 className="text-center text-wrap animate__animated animate__fadeInLeft animate__delay-1s">
                <p style={{ color: "#F58F50", fontSize: "50px", margin: "0" }}>
                {datosIdioma.equipo.nuestroEquipo}
                </p>
              </h1>
            </div>

            <div className="wrapper">
              <div className="container">
                <div className="row">
                  <Card
                    imgSrc="https://static.wixstatic.com/media/b91e83_f1f2bcadb3d74a4c9dd40cf038884993~mv2.jpg/v1/crop/x_0,y_451,w_4912,h_4070/fill/w_403,h_334,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/DSC_9056.jpg"
                    name={datosIdioma.equipo.nombreUno}
                    role={datosIdioma.equipo.puestoUno}
                    description={datosIdioma.equipo.descUno}
                    descriptionOther={datosIdioma.equipo.descUnoOtro}
                    link="https://www.linkedin.com/in/ceciliaperezg/"
                  />
                  <Card
                    imgSrc="https://static.wixstatic.com/media/b91e83_54fa230e6ca84c9aa71006db4d4bf757~mv2.jpg/v1/crop/x_0,y_561,w_4912,h_4070/fill/w_403,h_334,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/DSC_9071.jpg"
                    name={datosIdioma.equipo.nombreDos}
                    role={datosIdioma.equipo.puestoDos}
                    description={datosIdioma.equipo.descDos}
                    descriptionOther={datosIdioma.equipo.descDosOtro}
                    link="https://www.linkedin.com/in/manuel-edgardo-perez/"
                  />
                  <Card
                    imgSrc="https://static.wixstatic.com/media/b91e83_5afe6e80576a4b71a7baf53fb72d4913~mv2.jpg/v1/crop/x_0,y_768,w_4912,h_4070/fill/w_403,h_334,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/DSC_9097.jpg"
                    name={datosIdioma.equipo.nombreTres}
                    role={datosIdioma.equipo.PuestoTres}
                    description={datosIdioma.equipo.descTres}
                    descriptionOther={datosIdioma.equipo.descTresOtro}
                    link="https://www.linkedin.com/in/edgardo-perez-99487792/"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex align-items-center justify-content-center marequipo">
              <h1 className="text-center text-wrap animate__animated animate__fadeInLeft animate__delay-1s">
                <p style={{ color: "#000000", fontSize: "50px" }}>
                  {datosIdioma.equipo.info}
                </p>
              </h1>
            </div>
            <div className="col-md-12 text-center text-wrap estlismabu animate__animated animate__fadeInLeft animate__delay-1s">
              <button className="myButton" onClick={() => contacto()} >{datosIdioma.Inicio.agenda}</button>
            </div>
          </div>
        </div>
        <footer className="footer mt-auto py-3 bg-light animate__animated animate__fadeInLeft animate__delay-1s">
          <div className="container text-left">
            <span className="text-muted">
              {datosIdioma.Inicio.contacto} <br />​ 502 - 4770 - 9215 <br />​ info@empowerment.com
            </span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Eqipo;

const Card = ({ imgSrc, name, role, description, descriptionOther, link }) => {
  return (
    <div className="col-md-12 col-lg-4 animate__animated animate__fadeInRight animate__delay-2s">
      <div className="card mx-30">
        <img src={imgSrc} className="card-img-top" alt="..." />
        <div className="card-body">
          <h4 className="card-title">{role}</h4>
          <h5 className="title-color">{name}</h5>
          
          <p className="card-text">{description}</p>
          <p className="card-text mt-5"> {descriptionOther}</p>
          
          <div className="socials mt-5">
            <a href={link}>
              <i className="fa fa-linkedin fa-lg"></i>
            </a>
          </div>
         
          {(name === 'MANUEL PÉREZ' && localStorage.getItem('idioma') === 'EN')?(
          <></>
          ):(
            <br/>
          )}
        </div>
      </div>
      <br />
    </div>
  );
};
