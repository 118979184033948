import React, { useState, useEffect, useRef} from 'react';
import json2xls from 'json2xls';
import * as FileSaver from 'file-saver';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {
  Col,
  Row,
} from 'reactstrap';

import { 
  Table,
  Input,
  Space,
  Button,
  Tooltip
} from 'antd';
import { 
  SearchOutlined,  
  SendOutlined
} from '@ant-design/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { tokenDatos } from '../../conf/token';
import Highlighter from 'react-highlight-words';
import Widget from '../../components/Widget/Widget.js'
import ApexActivityChart from './components/ActivityChart.js'
import { useMutation } from '@apollo/client'
import gymIcon from '../../assets/dashboard/gymIcon.svg'
import therapyIcon from '../../assets/dashboard/therapyIcon.svg'
import statsPie from '../../assets/dashboard/statsPie.svg'
import bagIcon from "../../assets/tables/bagIcon.svg";
import s from './DashboardAdmin.module.scss'
import { reporteBornout } from '../../conf/mutation';
import UsuariosPendientes from './components/UsuariosPendientes.js';
import NivelCompromiso from './components/NivelCompromiso.js';
import { useHistory, useLocation} from 'react-router-dom';



const BornOut = () => {
  let history = useHistory();
  const searchInput = useRef(null);
  const [dataLogin, setDataLogin] = useState(tokenDatos())
  const [loadingData, setLoadingData] = useState(false)
  const [checkboxes, setCheckboxes] = useState([true, false])
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [Reporte] = useMutation(reporteBornout)


  const getDatos = async () => {   
    const { data } = await Reporte({
      variables: {
        input: { 
          EMP: dataLogin.CODIGOEMPRESA,
          ENC: dataLogin.ENCUESTANO
        },
      },
    })
    let json = JSON.parse(data.burnResultado.mensaje)
    setData(json)
    console.log(json)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Ingrese texto para buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Borrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const cancel = () => {
    setEditingKey('');
  };
  const HandleReenvio = async(record) => {
    console.log(record)
    try{
      const response = await axios.get(`http://137.184.217.236:3025/email/${record.EMAILEMP}/${record.NOMBRE}/${record.NOMGE}/${dataLogin.ENCUESTANO}/${dataLogin.CODIGOEMPRESA}/${record.CODEMP}`)
      const { data } = response
      console.log(data)
      Swal.fire({
        title: 'Información',
        text: 'Reenvio de correo exitoso',
        icon: 'success',
        confirmButtonColor: '#ff8f00',
        confirmButtonText: 'Ok'
    })
  }catch(e){
    console.log(e)
    Swal.fire({
      title: 'Error',
      text: 'Ha ocurrido un error, no se reenvio correo',
      icon: 'error',
      confirmButtonText: 'Ok'
  })
  }
 
}  

  const ReenviarCorreo = (record) =>{
    return(
        <Tooltip title="Reenviar correo">
            <Button type='primary' shape="circle" icon={<SendOutlined />} onClick={() => HandleReenvio(record)} />
        </Tooltip>
    );
}
  const columns = [
    {
      title: 'Código empleado',
      dataIndex: 'f0',
      key: 'f0',
      ...getColumnSearchProps('f0'),
    },
    {
      title: 'Nombre',
      dataIndex: 'f1',
      ...getColumnSearchProps('f1'),
    },
    {
      title: 'Puesto',
      dataIndex: 'f2',
      ...getColumnSearchProps('f2'),
    },
    {
      title: 'Repuesta cansancio',
      dataIndex: 'f3',
      ...getColumnSearchProps('f3'),
    },
    {
      title: 'Repuesta des personal',
      dataIndex: 'f4',
      ...getColumnSearchProps('f4'),
    },
    {
      title: 'Repuesta realización personal',
      dataIndex: 'f5',
      ...getColumnSearchProps('f5'),
    },
    {
      title: 'Resultado final',
      dataIndex: 'f6',
      ...getColumnSearchProps('f6'),
    },
  ]


  useEffect(() => {
    
    if(dataLogin.ROL === 'ADMINCOUCH'){
      history.push(`/template/dashboard`)
      
    }
    getDatos()
    
  }, [])
  return (
    <div>
      <div className="headline-2 mb-3">Burn Out</div>
        <Table 
            loading={loadingData} 
            columns={columns} 
            dataSource={data} 
            
            bordered
            pagination={{
                pageSize: 10,
            }}
        />
    </div>
  )
}

export default BornOut
