import React, { useState, useEffect }  from "react";
import{
  Skeleton,
  Space, 
  Table,
  } from 'antd';
import { encComentario } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import s from "../Dashboard.module.scss";
import { UserOutlined } from '@ant-design/icons';


const PreguntaAbierta = ({codigoEmp, idEncuesta}) => {

    
    const [dataPlan, setDataPlan] = useState([]);
    const [encRespuesta] = useMutation(encComentario)
    const [load, setLoad] = useState(true)
    const [data, setdata] = useState([]);

    /*const data =[
        {
          numero: 1,
          respuesta: 'Considero que mejorar el orden y gestión de proyectos a nivel de áreas y mejorar la atención de proveedores.',
        },
        {
          numero: 2,
          respuesta: 'En mi opinión sería agradable seguir de manera remoto ya que el trabajo que desempeñamos lo podemos realizar desde casa.',
        },
        {
          numero: 3,
          respuesta: 'Un mejor control en como es administrado el tiempo, existen tiempos de analisis, de investigacion, de configuracion de ambientes, reuniones, soporte con los usuarios, por llamada, whatsapp, soporte a plataformas anteriores o incluso apoyo que consumen tiempo por lo que la productividad no puede ser tomada de forma lineal, tienen diferentes aristas a ser tomadas en cuenta, ademas de incluir herramientas para poder llevar el correcto funcionamiento de la documentacion, ya que por correo se pierde el seguimiento para obtener las firmas correspondientes',
        },
      ]*/
      const columns = [
        
        {
          title: '',
          dataIndex: 'ID',
          key: 'ID',
          width: 50,
          render: (text, record) => (
            <Space >

                <UserOutlined 
                    style={{
                        color: '#ff8f00',
                        fontSize: '25px',
                      }}
                />
            </Space>
          ),

          
        },
        {
            title: 'Respuesta',
            dataIndex: 'COMENTARIO',
            key: 'COMENTARIO',  
        },
       
    ];
    const getPregunta = async () => {
      //console.log(codigoEmp)
      //console.log(idEncuesta)
      const {data} = await encRespuesta(
        {
          variables:{
            input: { CODJE: codigoEmp, ENC: idEncuesta }
          }
        }
      )
      //console.log('resp: ',JSON.parse(data.encComent.mensaje))
      let dataJson = JSON.parse(data.encComent.mensaje)
      //console.log(dataJson)
      
      setdata(dataJson)
      //setLabel(dataJson[2].dataLabel)
      setLoad(false)
      //console.log(op)
    }
    useEffect(() => {
      getPregunta()
    }, [])
  return (
    
    <div className="">
        
        <Table 
            loading={load} 
            columns={columns} 
            dataSource={data} 
            
            scroll={{ x: 800 }}
            bordered
        />
    </div>
  );
};

export default PreguntaAbierta;
