import React, {useState, useEffect} from "react";
import {
    Space, 
    Table,
    Tooltip,
    Button,
  } from 'antd'
import { Radar } from '@ant-design/plots';
import { TeamOutlined } from '@ant-design/icons';
import { encEquipo } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom'


const TablaEquipo = ({codigoEmp, idEncuesta, idEmpresa}) => {
  let history = useHistory()
  const [dataEquipo, setDataEquipo] = useState([]);
  const [encuestaEquipo] = useMutation(encEquipo)
  const [load, setLoad] = useState(true)

  const DashboardCouch = (record) => {
    console.log(record)
    history.push(`/template/dashboardCouch/${String(record.CODEMP)}/${record.NOMBRE}/${record.EMPRESA}/${record.ENCNO}`)
  }
  
  const getTotalEnc = async () => {
   
    //console.log(id)
    const {data} = await encuestaEquipo(
      {
        variables:{
          input: { CODJE: String(codigoEmp), EMP: String(idEmpresa), ENC: idEncuesta}
        }
      }
    )
    console.log('equipo: ',data.equipo.mensaje)
    let dataJson = JSON.parse(data.equipo.mensaje)
    setDataEquipo(dataJson.Equipo)
    setLoad(false)
    //console.log(dataTotalResp)
  }
  useEffect(() => {
    getTotalEnc()
  }, [])


      const columns = [
        
        {
          title: '#',
          dataIndex: 'CODEMP',
          key: 'CODEMP',

          width: 50,
        },
        {
            title: 'Nombre',
            dataIndex: 'NOMBRE',
            key: 'NOMBRE',  
        },
        {
          title: 'Puesto',
          dataIndex: 'PUESTO',
          key: 'PUESTO',  
        },
        
        {
          title: 'Retroalimentación',
          dataIndex: 'retroalimentacion',
          key: 'retroalimentacion',
          render: (text, record) => (
            <Space >
               <Tooltip title="Retroalimentación">
                    <Button icon={<TeamOutlined />} onClick ={() => DashboardCouch(record)}>Retroalimentación</Button> 
                </Tooltip>
            </Space>
          ),
          }
       
    ];
    
  return (
    <div >
      <Table 
            loading={load} 
            columns={columns} 
            dataSource={dataEquipo} 
            pagination={true} 
            bordered
        />
    </div>
  );
};

export default TablaEquipo;
