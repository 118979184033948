import React, { useState, useRef, useEffect } from "react";
import { withRouter, Redirect, Link } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import{
Drawer,
Tour,
Skeleton
} from 'antd';
import {
  Col,
  Row,
  Collapse
} from "reactstrap";
import { 
  PieChartOutlined ,
  TeamOutlined,
  MessageOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';
import { 
  Button,
  Stack,
  ButtonGroup,
  Tooltip,
  IconButton,
  useStepContext,
} from '@mui/material';
import { 
  encPunteoMP4, 
  EncAnterior, 
  punteoENPS,
  ListEncuestaAnt,
  finalizarTutorial
} from '../../conf/mutation';
import s from "./Dashboard.module.scss";
import {useMutation } from '@apollo/client';
import { tokenDatos } from '../../conf/token';
import TablaPlan from "./components/TablaPlan";
import QuizIcon from '@mui/icons-material/Quiz';
import TablaEquipo from "./components/TablaEquipo";
import Widget from "../../components/Widget/Widget.js";
import statsPie from "../../assets/dashboard/statsPie.svg";
import PreguntaAbierta from "./components/PreguntaAbierta";
import DetalleResultado from "./components/DetalleResultado";
import NivelCompromiso from "../ComponentesEstadisticas/NivelCompromiso.js";
import ResultadosRadar from "./components/ResultadosRadar.js";
import UsuariosPendientes from "../ComponentesEstadisticas/UsuariosPendientes";
import calendarIcon from "../../assets/navbarMenus/calendarIcon.svg";
import ResultadosAnteriores from "./components/ResultadosAnteriores";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AgregarPlan from "./components/AgregarPlan";
import { useHistory, useLocation} from 'react-router-dom';




const Dashboard = () => {
  let history = useHistory()
  const [open, setOpen] = React.useState(false);
  const [dataLogin] = useState(tokenDatos())
  const [dataEncuAnte, setDataEncuAnte] = useState([])
  const [encPunteo] = useMutation(encPunteoMP4)
  const [encEnps] = useMutation(punteoENPS)
  const [encAnterior] = useMutation(EncAnterior)
  const [ListEncAnterior] = useMutation(ListEncuestaAnt)
  const [finalTutorial] = useMutation(finalizarTutorial)
  const [punteoMP4, setPunteoMP4] = useState(0)
  const [loadMP4, setLoadMP4] = useState(true)
  const [loadEnps, setloadEnps] = useState(true)
  const [enps, setEnps] = useState(0)
  const [loadEncAnt, setloadEncAnt] = useState(true)
  const [dataEncAnteriores, setdataEncAnteriores] = useState([])
  const [loadResulEncAnt, setResulEncAnt] = useState(true)
  const [tituloDrawer, setTituloDrawer] = useState("");
  const [tipo, setTipo] = useState("")
  const [tipoRadar, setTipoRadar] = useState(1);
  const [openResulAnt, setResulAnt] = useState(false);
  const [openPregunta, setOpenPregunta] = useState(false);
  const [openAgregarPlan, setOpenAgregarPlan] = useState(false);
  const [isOpenPlanActivo, setIsOpenPlanActivo] = useState(true);
  const [isOpenPlanFinalizado, setIsOpenPlanFinalizado] = useState(false);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [openTour, setOpenTour] = useState(dataLogin.TUTORIAL === 1 ? false : true);//
  const steps = [
    {
      title: '¡Bienvenido!',
      description: 'Esta es tu plataforma de Empowerment.  Aquí podrás visualizar las necesidades de tu equipo y detallar los planes de acción que trabajarás con ellos.',
      cover: (
        <>
          <Row>
            <Col>
              <TeamOutlined style={{
                fontSize: '50px',
                color: '#16365f'
              }} />
            </Col>
            <Col>
              <MessageOutlined 
                style={{
                  fontSize: '50px',
                  color: '#ff8f00'
                }} 
              />
            </Col>
            <Col>
              <PieChartOutlined 
              style={{
                fontSize: '50px',
                color: '#16365f'
              }} />
            </Col>
            <Col>
              <RadarChartOutlined style={{
                fontSize: '50px',
                color: '#ff8f00'
              }} />
            </Col>
          </Row>
        
        </>
      ),
      nextButtonProps:{
        children: "Siguiente"
      }
    },
    {
      title: '',
      description: 'Aquí encontrarás tus notificaciones.  Podrás visualizar tu calendario de actividades y cerrar sesión cuando finalices. ',
      target: () => document.getElementById("34"),
      nextButtonProps:{
        children: "Siguiente"
      },
      prevButtonProps:{
        children: "Anterior"
      },
    },
    {
      title: 'Opciones de menú',
      description: 'Aquí podrás navegar a la página principal y a tu calendario.',
      target: () => document.getElementById("35"),
      placement: 'right',
      nextButtonProps:{
        children: "Siguiente"
      },
      prevButtonProps:{
        children: "Anterior"
      },
    },
    {
      title: 'Resultados',
      description: 'Estos son los resultados principales de tu encuesta.  Puedes observar cuales son las áreas que requieren la mayor atención en tu equipo.',
      target: () => document.getElementById("36"),
      placement: 'rightTop',
      nextButtonProps:{
        children: "Siguiente"
      },
      prevButtonProps:{
        children: "Anterior"
      },
    },
    {
      title: 'Punteo MP4',
      description: 'Este punteo sirve para medir tu progreso a través del tiempo.  Es el resultado de un análisis de los resultados de los miembros de tu equipo.  ',
      target: () => document.getElementById("37"),
      placement: 'bottom',
      nextButtonProps:{
        children: "Siguiente"
      },
      
      prevButtonProps:{
        children: "Anterior"
      },
    },
    {
      title: 'Resultado ENPS',
      description: 'El ENPS representa qué tan probable es que tu equipo recomiende la organización como lugar de trabajo.  El punteo puede ir de -100 hasta +100.  ',
      target: () => document.getElementById("38"),
      placement: 'bottom',
      nextButtonProps:{
        children: "Finalizar",
        onClick: () => setFinTutorial()
     
      },
      prevButtonProps:{
        children: "Anterior",
        
      },
    },
  ];
  const setFinTutorial = async () => {
    console.log("fin tutorial")
    const {data} = await finalTutorial(
      {
        variables:{
          input: { CODEMP: String(dataLogin.CODEMP)}
        }
      }
    )
  
    //console.log('encuestaAnt', data.encanterior.mensaje)
    let dataJson = JSON.parse(data.tutorial.mensaje)
    console.log('tutorial', dataJson)
    //console.log(dataTotalResp)
  }
  const AbrirPlanActivo = () => setIsOpenPlanActivo(!isOpenPlanActivo);
 
  const AbrirPlanFinalizado = () => setIsOpenPlanFinalizado(!isOpenPlanFinalizado);
 
  const CloseDrawer = () => {
    setOpenAgregarPlan(false)
    setResulAnt(false)
    setOpenPregunta(false)
    setOpen(false)
    toggleDrawer(false, 0)
    setIsOpenPlanActivo(!isOpenPlanActivo)

    
  }

  const cierreAgregarPlan = () => {

    setIsOpenPlanActivo(false)

    
  }
  const AbrirAgregarPlan = (newOpen) => () => {
    //console.log('cierre resultado')
    setIsOpenPlanActivo(!isOpenPlanActivo)
    setOpenAgregarPlan(newOpen);
  };
  const cierreResultado = (newOpen, dataEnc) => () => {
    //console.log('cierre resultado', dataEnc)
    setResulAnt(newOpen);
    setDataEncuAnte(dataEnc);

  };
  const cierrePregunta = (newOpen) => () => {
    //console.log('cierre resultado')
    setOpenPregunta(newOpen);
  };
  const toggleDrawer = (newOpen, titulo) => () => {

    setOpen(newOpen);
    if(newOpen){
      setTipoRadar(2)
    }else{
      setTipoRadar(1)
    }
    
    switch(titulo) {
      case 1:
        setTituloDrawer('Dirección y objetivos')
        setTipo('Direccion y objetivo')
        break;
      case 2:
        setTituloDrawer('Incentivos y motivación')
        setTipo('Incentivo y motivacion')
        break;
      case 3:
        setTituloDrawer('Pertenencia')
        setTipo('Pertenencia')
        break;
      case 4:
        setTituloDrawer('Autorrrealización')
        setTipo('Autorrealizacion')
        break;
    }
  };
  const getPunteoMP4 = async () => {
    //let codEmp = localStorage.getItem('emp')
   
    //console.log(codJe)
    try{
      const {data} = await encPunteo(
        {
          variables:{
            input: { CODJE: String(dataLogin.CODEMP), ENC: dataLogin.ENCUESTANO}
          }
        }
      )
      //console.log('resp: ',JSON.parse(data.encChatTot.mensaje))
      
      let dataJson = JSON.parse(data.punteoMP4.mensaje)
      if(dataJson.mp4 === null){

      }else{
        setPunteoMP4(dataJson.mp4)
        //console.log('punteo mp4', dataJson.mp4)
        setLoadMP4(false)
      }
      
    }catch(ex){
      console.log(ex)
    }
    
    //console.log(dataTotalResp)
  }
  const getListEncuesta = async () => {
    //console.log(codJe)
    const {data} = await ListEncAnterior(
      {
        variables:{
          input: { CODJE: String(dataLogin.CODEMP), EMP: dataLogin.CODIGOEMPRESA, TIP: "", ENC: dataLogin.ENCUESTANO }
        }
      }
    )
  
    console.log('encuestaAnt', data.encanterior.mensaje)
    let dataJson = JSON.parse(data.encanterior.mensaje)
    //console.log('encuestaAnt', dataJson)
    setloadEncAnt(false)
    setdataEncAnteriores(dataJson.respuesta)
    //console.log(dataTotalResp)
  }
  const getUltimasEnc = async () => {
   
    //console.log(id)
    const {data} = await encAnterior(
      {
        variables:{
          input: { CODJE: String(dataLogin.CODEMP), ENC: dataLogin.ENCUESTANO}
        }
      }
    )
    //console.log('resp: ',JSON.parse(data.encChatTot.mensaje))
    let dataJson = JSON.parse(data.punteoMP4.mensaje)
    //console.log(dataJson)
    //setdata(obj)
    //setPunteoMP4(5)
    setResulEncAnt(false)
    //console.log(dataTotalResp)
  }

  const getEnps = async () => {
    //console.log(id)
    try{
      const {data} = await encEnps(
        {
          variables:{
            input: { CODJE: String(dataLogin.CODEMP), EMP: dataLogin.CODIGOEMPRESA, ENC: dataLogin.ENCUESTANO }
          }
        }
      )
      //console.log('resp: ',JSON.parse(data.encChatTot.mensaje))
      let dataJson = JSON.parse(data.punteoENPS.mensaje)
      //console.log('enps: ', dataJson)
      if(dataJson.respuesta[0].f0 === null){
        
      }else{
        setEnps(dataJson.respuesta[0].f0)
        setloadEnps(false)
      }
     
    }catch(ex){
      console.log('enps catch', ex)
    }
    
    //console.log(dataTotalResp)
  }
  const buttonNext = () => {
return(
  <Button>
    Siguiente
  </Button>
)
  }
  function format(inputDate) {
        
    let date, month, year;
    const datePlan = new Date(inputDate);
    console.log(datePlan)
    date = datePlan.getDate();
    month = datePlan.getMonth()+1;
    year = datePlan.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${date}/${month}/${year}`;
  }
  useEffect(() => {
    if(dataLogin.ROL === 'ADMINEMP'){
      console.log('entro a validador')
      history.push('/template/dashboardAdmin')
    }
    if(dataLogin.ROL === 'ADMIN'){
     
      history.push('/template/empresas')
      
    }
    getPunteoMP4()
    getEnps()
    getListEncuesta()
    //getUltimasEnc()
    //console.log('dashboard', dataLogin)
  }, [])
  const nextButtonProps = {
    
  }

  return (
    <div>
      
      <Tour 
        open={openTour} 
        onClose={() => setOpenTour(false)} 
        steps={steps} 
        arrow={true}
         />
      <div className="headline-2 mb-3">Dashboard</div>
      <Row  className="gutter mb-2">
        <Col className="mb-2 mt-2 mb-md-0" xs={12} md={12} lg={5} xl={5}>
          <Widget className="widget-p-md" id="36">
            <div className="headline-2 mb-3">Resultados</div>
            <ResultadosRadar codigoEmp = {String(dataLogin.CODEMP)} idEncuesta = {dataLogin.ENCUESTANO}/>
          </Widget>
        </Col>
        <Col className = "mt-2" xs={12}  md={12} lg={3} xl={3}>
          <Widget className="widget-p-md centrado" >
            <Stack spacing={5} alignItems="center" id="37">
              <>
              
               <div className="headline-2 mb-3">Punteo MP4</div>
                  {
                    (loadMP4)?
                    ( 
                      <Skeleton className="mt-5"></Skeleton>
                    ):
                    (
                      
                          <div className={s.circle}>
                            {punteoMP4}
                          </div>
                      
                    )
                  }
                
              </> 
            </Stack>
            <Stack spacing={2} alignItems="center" id="38">
              <>
                  {
                    (loadEnps)?
                    ( 
                      <Skeleton className="mt-5"></Skeleton>
                    ):
                    (
                      <div>
                        <div  >
                          <div className={s.content}>
                            <div><img className={s.pieImg}  src={statsPie} alt="..." /></div>
                            <div className="body-2"><p className="body-2 ml-1 muted-light-gray">ENPS = {enps}</p></div>
                            </div>
                        </div>
                      </div>
                    )
                  }
                </>
            </Stack>
          </Widget>
        </Col>
        <Col className="pr-grid-col mb-6 mt-2" xs={12}  md={12} lg={4}  xl={4}>
          <Widget className="widget-p-md centrado">
            <NivelCompromiso codigoEmp = {String(dataLogin.CODEMP)} idEncuesta = {dataLogin.ENCUESTANO}></NivelCompromiso>
            <Button fullWidth={true} onClick={cierrePregunta(true)} variant="outlined" color="baseDarkGray" startIcon={<QuizIcon />}  size="large">Pregunta abierta</Button>
          </Widget>
        </Col>
      </Row>
      <Row className="gutter mb-4">  
        <Col className="mb-2 mt-3 mb-md-0" xs={12}  md={12} lg={5}  xl={5}>
          
              <Widget className="widget-p-md centrado">
                <div className="headline-2 mb-3">Detalle de resultados</div>
                <Stack spacing={2} alignItems="flex-start" justifyContent="center" direction="column">
                 
                      <Button fullWidth={true} variant="outlined" color="secondaryYellow" onClick={toggleDrawer(true, 1)} ref={ref1}>DIRECCIÓN Y OBJETIVOS</Button>
                      <Button fullWidth={true} variant="outlined" color="secondaryYellow" onClick={toggleDrawer(true, 2)}>INCENTIVOS Y MOTIVACIÓN</Button>
                      <Button fullWidth={true} variant="outlined" color="secondaryYellow" onClick={toggleDrawer(true, 3)}>PERTENENCIA</Button>
                      <Button fullWidth={true} variant="outlined" color="secondaryYellow" onClick={toggleDrawer(true, 4)}>AUTORREALIZACIÓN</Button>
                </Stack>
              </Widget>
        </Col>
        <Col className="mb-2 mt-3 mb-md-0" xs={12}  md={12} lg={3}  xl={3} >
              <Widget className="widget-p-md">
                <div className="headline-2 mb-3">Usuarios y respuesta</div>
                  <UsuariosPendientes idEmpresa = {String(dataLogin.CODIGOEMPRESA)} idEncuesta = {dataLogin.ENCUESTANO} codEmp = {String(dataLogin.CODEMP)}></UsuariosPendientes>
              </Widget>
            </Col>
        <Col className="mb-4 mt-3 mb-md-0" xs={12}  md={12} lg={4}  xl={4}>
          <Widget className="widget-p-md centrado">
            <>
              {
                (loadEncAnt)?
                ( 
                  <Skeleton className="mt-5"></Skeleton>
                ):
                (
                  <div>
                    <div className="d-flex">
                      <div className="headline-2 mb-3">Resultados anteriores</div>
                      
                    </div>
                    <div className={`mt-3 ${s.widgetBlock}`}>
                      <div className={s.widgetBody}>
                        <React.Fragment>
                          {dataEncAnteriores.map((item) => (
                            <>
                              <div className="d-flex">
                              <img className="img-fluid mr-2" src={calendarIcon} alt="..." />
                              <div className="d-flex flex-column">
                                <p className="body-2">{format(item.FECHA)}</p>
                                <p className="body-3 muted">Encuesta no. {item.ENCNO}</p>
                              </div>
                              </div>
                              <div className="checkbox checkbox-primary">
                                <Tooltip title="Ver resultados">
                                  <IconButton onClick={cierreResultado(true, item)} ref={ref2}>
                                    <RemoveRedEyeOutlinedIcon color="darkBlack" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </>
                          ))}     
                        </React.Fragment>           
                      </div>
                    </div>
                    
                  </div>
                )
              }
            </>
            
          </Widget>
        </Col>
        <Col className="mb-2 mt-3 mb-md-0" xs={12}  md={12} lg={12}  xl={12}>
              <Widget className="widget-p-md centrado">
                <div className="headline-2 mb-3">Plan de acción</div>
                <Stack spacing={2} alignItems="center" justifyContent="center" direction="column">
                  <ButtonGroup fullWidth={true} size="medium" variant="contained" color="baseDarkGray" aria-label="outlined primary button group">
                    <Button onClick={AbrirPlanActivo} >Activos</Button>
                    <Button onClick={AbrirPlanFinalizado}>Finalizados</Button>
                    <Button onClick={AbrirAgregarPlan(true)}>Agregar</Button>
                  </ButtonGroup>
                </Stack>
                <div className="mt-5">
               
                  {
                    (isOpenPlanActivo === true) && (
                      <Collapse isOpen={isOpenPlanActivo} >
                        <div className="d-flex flex-column mb-4">
                         
                        </div>
                        
                        <TablaPlan tipo = {1}  tip={"0"} codigoEmp = {dataLogin.CODEMP} idEncuesta={dataLogin.ENCUESTANO} idEmpresa ={dataLogin.CODIGOEMPRESA} consulta ={0} handleClose={cierreAgregarPlan}></TablaPlan>
                      </Collapse>
                    )
                  }
        
                </div> 
                <div className="mt-5">
               
               {
                 (isOpenPlanFinalizado === true) && (
                   <Collapse isOpen={isOpenPlanFinalizado} >
                      <div className="d-flex flex-column mb-4">
                          <p className="headline-3">Finalizados</p>
                        </div>
                     <TablaPlan tipo = {2} tip={"1"} codigoEmp = {dataLogin.CODEMP} idEncuesta={dataLogin.ENCUESTANO} idEmpresa ={dataLogin.CODIGOEMPRESA} consulta ={0}></TablaPlan>
                   </Collapse>
                 )
               }
         

                 
               </div> 
              </Widget>
            </Col>
            <Col className="mb-2 mt-3 mb-md-0" xs={12}  md={12} lg={12}  xl={12}>
              <Widget className="widget-p-md centrado">
                <div className="headline-2 mb-3">Mi equipo</div>
                <div className="mt-2">
                  <TablaEquipo codigoEmp = {dataLogin.CODEMP} idEncuesta={dataLogin.ENCUESTANO} idEmpresa ={dataLogin.CODIGOEMPRESA} ></TablaEquipo>
                
                </div> 
              </Widget>
            </Col>
      </Row>

      {
        (open === true) && (
          <>
            <Drawer
              title={tituloDrawer}
              placement="right"
              size={'large'}
              width={'80%'}
              onClose={CloseDrawer}
              open={open}
            >
              {
                  open && (
                    <DetalleResultado 
                      tipo = {tipo} 
                      codigoEmp = {String(dataLogin.CODEMP)} 
                      idEncuesta = {dataLogin.ENCUESTANO}
                      idEmpresa = {dataLogin.CODIGOEMPRESA}
                      handleClose={CloseDrawer} 
                    ></DetalleResultado>
                  )
              }
            </Drawer>
          </>
        )
        
      }
      {
        
        (openResulAnt === true) && (
          <>
            <Drawer
              title="Resultado anterior"
              placement="right"
              size={'large'}
              width={'50%'}
              onClose={CloseDrawer}
              open={openResulAnt}
            >
              {
                  openResulAnt && (
                    <ResultadosAnteriores
                    dataEncuAnte= {dataEncuAnte}
                    
                    handleClose={CloseDrawer} >
                    </ResultadosAnteriores>
                  )
              }
            </Drawer>
          </>
        )
        
      }
      {
        (openPregunta === true) && (
          <>     
            <Drawer
              title="Pregunta abierta"
              placement="right"
              size={'large'}
              width={'60%'}
              onClose={CloseDrawer}
              open={openPregunta}
            >
              {
                  openPregunta && (
                      <PreguntaAbierta
                        codigoEmp = {String(dataLogin.CODEMP)} 
                        idEncuesta = {dataLogin.ENCUESTANO}
                        handleClose= {CloseDrawer}
                      />
                
                  )
              }
            </Drawer>
          </>
        )
        
      }
      {
        (openAgregarPlan === true) && (
          <>    
           <Drawer
            title="Agregar plan de acción"
            placement="right"
            size={'large'}
            width={'50%'}
            onClose={CloseDrawer}
            open={openAgregarPlan}
            >
            {
                openAgregarPlan && (
                    <AgregarPlan  
                    handleClose= {CloseDrawer}
                    />
                )
            }
            </Drawer>
          </>
        )
        
      }
            {/*<div className={s.userParams}>
              <div className="d-flex flex-column">
                <p className="headline-3">63 kg</p>
                <p className="body-3 muted">Weight</p>
              </div>
              <div className="d-flex flex-column">
                <p className="headline-3">175 sm</p>
                <p className="body-3 muted">Height</p>
              </div>
              <div className="d-flex flex-column">
                <p className="headline-3">28 y.</p>
                <p className="body-3 muted">Age</p>
              </div>
            </div>*/}


            {/*<p className="headline-3">Appointments</p>
            <div className={`mt-3 ${s.widgetBlock}`}>
              <div className={s.widgetBody}>
                <div className="d-flex">
                  <img className="img-fluid mr-2" src={gymIcon} alt="..." />
                  <div className="d-flex flex-column">
                    <p className="body-2">02.11 , 12:00 - 13:00</p>
                    <p className="body-3 muted">Yoga, Airplace Gym</p>
                  </div>
                </div>
                <div className="checkbox checkbox-primary">
                  <input
                    id="checkbox0"
                    type="checkbox"
                    className="styled"
                    checked={checkboxes[0]}
                    onChange={() => toggleCheckbox(0)}
                  />
                  <label htmlFor="checkbox0" />
                </div>
              </div>
            </div>
            <div className={`mt-3 ${s.widgetBlock}`}>
              <div className={s.widgetBody}>
                <div className="d-flex">
                  <img className="img-fluid mr-2" src={therapyIcon} alt="..." />
                  <div className="d-flex flex-column">
                    <p className="body-2">03.11 , 16:00 - 17:30</p>
                    <p className="body-3 muted">Therapy</p>
                  </div>
                </div>
                <div className="checkbox checkbox-primary">
                  <input
                    id="checkbox1"
                    type="checkbox"
                    className="styled"
                    checked={checkboxes[1]}
                    onChange={() => toggleCheckbox(1)}
                  />
                  <label htmlFor="checkbox1" />
                </div>
              </div>
            </div>
            <a className={`btn-secondary-red ${s.statsBtn}`} href="#top" role="button">
              <img className={s.pieImg}  src={statsPie} alt="..." />
              <div>
                <p className="headline-2">STATISTIC</p>
                <p className="body-3">Download your activity</p>
              </div>
              </a>*/}
    </div>
  )
}

export default Dashboard;
